<template>
    <div class="app-input-datetime-picker"
        :class="{ disabled, error }"
    >
        <h5 class="heading" v-if="label">{{ label }}</h5>

        <div class="pickers">
            <app-input-datepicker v-model="date" :label="labelDate" :required="required" :placeholder="placeholderDate" :disabled="disabled"></app-input-datepicker>
            <app-input-timepicker v-model="time" :label="labelTime" :required="required"                                :disabled="disabled || !is_date_selected"></app-input-timepicker>
        </div>

        <div class="error-message" v-if="error">{{ error }}</div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appInputDatepicker from '@/components/app-input-datepicker'
import appInputTimepicker from '@/components/app-input-timepicker'

export default {    
    props: {
        value:           { required: false },

        required:        { type: Boolean, default: false },
        disabled:        { type: Boolean, default: false },
        error:           {                default: false },

        label:           { type: String, default: '' },
        labelDate:       { type: String, default: 'Date' },
        labelTime:       { type: String, default: 'Time' },

        useNanos:        { type: Boolean, default: true },
        
        placeholderDate: { default: '' },
    },

    components: {
        appInputDatepicker,
        appInputTimepicker,
    },

    methods: {
        setDatetime(value) {
            this.$emit('input', value)
            this.$emit('change', value)
        },
    },

    computed: {
        date: {
            get() {
                return this.value
                    ? (
                        this.value instanceof Date
                            ? this.value
                            : new Date(this.useNanos ? this.value / 1000000 : this.value)
                    )
                    : this.value
            },
            set(value) {
                if (!this.is_date_selected) {
                    value.setHours(0, 0, 0, 0)
                }

                this.setDatetime(value)
            },
        },

        time: {
            get() {
                let HH, mm

                if (this.is_date_selected) {
                    [ HH, mm ] = this.date.toLocaleTimeString("hi-IN", {
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false
                    }).split(':')

                    if (HH > 23) { HH = "00" }
                }

                return { HH, mm }
            },
            set({ HH, mm }) {
                if (this.is_date_selected) {
                    let date = new Date(this.date.getTime())
                        date.setHours(HH, mm, 0, 0)

                    this.setDatetime(date)
                }
            },
        },

        is_date_selected() {
            return Boolean(this.date)
        },
    },
}
</script>

<style lang="scss">
.app-input-datetime-picker {
    h5 {
        margin-bottom: 24px;
    }

    .pickers {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .app-input-datepicker {
            flex-grow: 1;
        }

        .app-input-timepicker {
            flex-shrink: 0;
            width: 35%;
            margin-left: 30px;
        }
    }
}

@media (max-width: $tablet-size) {}

@media (max-width: $mobile-size) {
    .app-input-datetime-picker {
        .pickers {
            .app-input-timepicker {
                margin-left: 15px;
            }
        }
    }
}
</style>