<template>
    <div id="page-product-characteristic" :class="{ 'on-sidebar': on_sidebar }">
        <app-loader v-if="loading" fixed-on="desktop" />

        <div class="container">
            <div class="row" v-if="!on_sidebar">
                <div class="col-12">
                    <router-link class="btn btn-back-to" :to="product_route">Back to Product</router-link>

                    <div class="header-info">
                        <h1 class="heading"><span v-if="is_new">Adding</span> Product <span v-if="product.Name">"{{ product.Name }}"</span> Characteristic <span v-if="original.Name">"{{ original.Name }}"</span></h1>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-mob-12"
                    :class="on_sidebar ? 'col-12' : 'col-6'"
                >
                    <div class="board">
                        <h3 class="heading">Details</h3>

                        <app-loader v-if="processing" />

                        <app-error v-model="errors.save" />

                        <app-input
                            v-model="characteristic.Name"

                            label="Name"

                            :required="true"

                            :maxlength="64"

                            :disabled="disabled"
                            :error="errors.Name"

                            @change="errors.Name = null"
                        />

                        <app-dropdown-select
                            v-if="value_type == 'bool'"

                            v-model="characteristic.DefaultValue"

                            label="Default Value"

                            :options="bool_options"

                            :error="errors.DefaultValue"
                            :disabled="disabled"

                            @change="errors.DefaultValue = null"
                        />

                        <app-input
                            v-else

                            v-model="characteristic.DefaultValue"

                            label="Default Value"

                            :maxlength="128"

                            :disabled="disabled"
                            :error="errors.DefaultValue"

                            @change="errors.DefaultValue = null"
                        />

                        <app-dropdown-select v-model="characteristic.Required"     label="Required"      :options="dictonaries.bool" :error="errors.Required"     @change="errors.Required = null"     :disabled="disabled" />
                        <app-dropdown-select v-model="characteristic.ValueType"    label="Value Type"    :options="dictonaries.type" :error="errors.ValueType"    @change="errors.ValueType = null"    :disabled="disabled" />
                        <app-dropdown-select v-model="characteristic.CanAdd"       label="Allow Customer to override on Add"       :options="dictonaries.bool" :error="errors.CanAdd"       @change="errors.CanAdd = null"       :disabled="disabled" />
                        <app-dropdown-select v-model="characteristic.CanUpdate"    label="Allow Customer to override on Update"    :options="dictonaries.bool" :error="errors.CanUpdate"    @change="errors.CanUpdate = null"    :disabled="disabled" />

                        <button class="btn btn-small btn-primary" @click="save" :disabled="disabled || !is_changed" v-if="!on_sidebar">Save</button>
                    </div>

                    <button class="btn btn-full-width btn-primary" @click="save" :disabled="disabled || !is_changed" v-if="on_sidebar">Save</button>
                </div>
            </div>
        </div>



        <dialog-product-characteristic-add-success
            v-if="dialogs.success.show"
            v-model="dialogs.success.show"

            @closes="onSuccessDialogCloses"
        ></dialog-product-characteristic-add-success>
    </div>
</template>

<script>
import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appInput from '@/components/app-input'
import appDropdownSelect from '@/components/app-dropdown-select'

import dialogProductCharacteristicAddSuccess from '@/views/product/dialogs/dialog-product-characteristic-add-success'

import ProductCharacteristicsValidator from '@/validators/product-characteristics-validator'
import errMessage from '@/helpers/errMessage'

export default {
    props: {
        uuid: { type: String, default: null },
        charUuid: { type: String, default: null },
    },

    components: {
        appLoader,
        appError,
        appInput,
        appDropdownSelect,

        dialogProductCharacteristicAddSuccess,
    },

    data() {
        return {
            product: {},

            characteristic: {
                Name: null,
                DefaultValue: null,
                Required: null,
                ValueType: null,
                CanAdd: false,
                CanUpdate: false,
            },
            original: {},

            dictonaries: {
                yes_no: [
                    { id: 'Yes', name: 'Yes' },
                    { id: 'No',  name: 'No'  },
                ],

                bool: [
                    { id: true,  name: 'Yes' },
                    { id: false, name: 'No'  },
                ],

                bool_with_blank: [
                    { id: '',    name: '(blank)' },
                    { id: true,  name: 'Yes'     },
                    { id: false, name: 'No'      },
                ],

                type: [
                    { id: '',       name: '(none)' },
                    { id: 'bool',   name: 'Bool'   },
                    { id: 'string', name: 'String' },
                    { id: 'number', name: 'Number' },
                    { id: 'MSISDN', name: 'MSISDN' },
                    { id: 'IMSI',   name: 'IMSI'   },
                ],
            },

            dialogs: {
                success: {
                    show: false,
                    characteristic: null,
                },
            },

            loading: false,
            processing: false,

            errors: {},

            on_sidebar: false,
        }
    },

    created() {
        this.init()
    },

    mounted() {
        this.on_sidebar = this.$parent.$el.classList.contains('app-sidebar-details')
    },

    methods: {
        init() {
            this.loadProductCharacteristic()
        },

        loadProductCharacteristic() {
            const loading = `${ this.prod_uuid } - ${ this.char_uuid }`

            if (this.loading != loading) {
                this.loading = loading
                this.processing = false
                this.errors = {}

                return this.$store.dispatch('api_product_catalog/getCachedProductCatalogEntryByUUID', { UUID: this.prod_uuid }).then(product => {
                    if (this.loading == loading) {
                        this.product = product
                        
                        if (this.is_new) {
                            for (const key in this.characteristic) {
                                this.characteristic[ key ] = null
                            }
                            this.original = { ...this.characteristic }

                            this.loading = false
                        } else {
                            return this.$store.dispatch('api_product_catalog/getCachedProductCatalogCharacteristicByUUID', { UUID: this.char_uuid }).then(characteristic => {
                                if (this.loading == loading) {
                                    if (characteristic.ProductCatalogEntryUUID == this.prod_uuid) {
                                        for (const key in this.characteristic) {
                                            this.characteristic[ key ] = characteristic[ key ]
                                        }
                                        this.original = { ...characteristic }

                                        this.loading = false
                                    } else {
                                        this.exit(this.product_route)
                                    }
                                }
                            }).catch(error => {
                                if (this.loading == loading) {
                                    console.error('api_product_catalog/getCachedProductCatalogCharacteristicByUUID', error)

                                    this.exit(this.product_route)
                                }
                            })
                        }
                    }

                    return Promise.resolve(product)
                }).catch(error => {
                    if (this.loading == loading) {
                        console.error('api_product_catalog/getCachedProductCatalogEntryByUUID', error)

                        this.exit({ name: 'products' })
                    }
                })
            }
        },

        save() {
            if (this.is_changed) {
                if (this.validation()) {
                    const processing = `${ this.prod_uuid } - ${ this.char_uuid }`

                    if (this.processing != processing) {
                        this.processing = processing

                        if (this.is_new) {
                            this.add(processing)
                        } else {
                            this.update(processing)
                        }
                    }
                } else {
                    if (this.on_sidebar) {
                        this.$parent.$refs.body.scrollTo(0, 0)
                    } else {
                        window.scrollTo(0, 0)
                    }
                }
            }
        },

        add(processing) {
            const payload = {
                ...this.characteristic,
                ProductCatalogEntryUUID: this.prod_uuid,
            }

            this.$store.dispatch('api_product_catalog/AddProductCatalogCharacteristic', payload)
                .then(characteristic => {
                    if (this.processing == processing) {
                        for (const key in this.characteristic) {
                            this.characteristic[ key ] = characteristic[ key ]
                        }
                        this.original = { ...characteristic }

                        this.showSuccessDialog(characteristic)

                        this.processing = false
                    }
                })
                .catch(error => {
                    this.$set(this.errors, 'save', errMessage(error))

                    this.processing = false
                })
        },

        update(processing) {
            let payload = {
                UUID: this.char_uuid,
            }

            for (const key in this.characteristic) {
                if (this.characteristic[ key ] !== this.original[ key ]) {
                    payload[ key ] = this.characteristic[ key ]
                }
            }

            this.$store.dispatch('api_product_catalog/UpdateProductCatalogCharacteristic', payload)
                .then(characteristic => {
                    if (this.processing == processing) {
                        for (const key in this.characteristic) {
                            this.characteristic[ key ] = characteristic[ key ]
                        }
                        this.original = { ...characteristic }

                        if (this.on_sidebar) {
                            this.$emit('updated', characteristic)
                        }

                        this.processing = false
                    }
                })
                .catch(error => {
                    this.$set(this.errors, 'save', errMessage(error))

                    this.processing = false
                })
        },

        validation() {
            let is_valid = true

            this.errors = {}

            const values = { ...this.characteristic }

            if (this.value_type == 'number') {
                const pattern = /^[-+]?\d+(\.\d+)?$/

                if (!pattern.test(this.characteristic.DefaultValue)) {
                    is_valid = false

                    this.errors.DefaultValue = 'Please, enter valid number'
                }
            } else if (this.value_type == 'bool') {
                let is_default_value_valid = false

                for (let i = 0, len = this.bool_options.length; i < len; i++) {
                    if (this.characteristic.DefaultValue == this.bool_options[i].id) {
                        is_default_value_valid = true
                        break
                    }
                }

                if (!is_default_value_valid) {
                    is_valid = false

                    this.errors.DefaultValue = 'Please, select default value'
                }
            }

            for (const field in values) {
                if (ProductCharacteristicsValidator.isRuleExists(field)) {
                    const error = ProductCharacteristicsValidator.isInvalid(field, values[field])

                    if (error) {
                        is_valid = false

                        this.$set(this.errors, field, error)
                    }
                }
            }

            return is_valid
        },

        exit(route) {
            if (this.on_sidebar) {
                this.$emit('close-sidebar')
            } else if (route) {
                this.$router.push({ ...route, replace: true })
            }
        },

        showSuccessDialog(characteristic) {
            this.dialogs.success.show = true
            this.dialogs.success.characteristic = characteristic
        },

        onSuccessDialogCloses() {
            if (this.on_sidebar) {
                this.$emit('added', this.dialogs.success.characteristic)
            } else {
                this.exit({ name: this.$route.name, params: { ...this.$route.params, char_uuid: this.dialogs.success.characteristic.UUID } })
            }
        },
    },

    computed: {
        prod_uuid() {
            return this.uuid || this.$route.params.uuid
        },

        char_uuid() {
            return this.charUuid || this.$route.params.char_uuid
        },

        is_new() {
            return !this.char_uuid
        },

        is_changed() {
            let is_changed = false

            for (const key in this.characteristic) {
                if (this.characteristic[ key ] !== this.original[ key ]) {
                    is_changed = true
                    break
                }
            }

            return is_changed
        },

        product_route() {
            return { name: 'product', params: { uuid: this.prod_uuid } }
        },

        disabled() {
            return Boolean(this.loading) || Boolean(this.processing)
        },

        value_type() {
            return this.characteristic.ValueType
        },

        bool_options() {
            let bool_options = [
                {
                    id:   'true',
                    name: 'True',
                },
                {
                    id:   'false',
                    name: 'False',
                },
            ]

            if (this.characteristic.DefaultValue == 'Yes' || this.characteristic.DefaultValue == 'No') {
                bool_options = [
                    {
                        id:   'Yes',
                        name: 'True',
                    },
                    {
                        id:   'No',
                        name: 'False',
                    },
                ]
            }

            return bool_options
        },
    },

    watch: {
        prod_uuid() {
            this.loadProductCharacteristic()
        },

        char_uuid() {
            this.loadProductCharacteristic()
        },
    },
}
</script>

<style lang="scss">
#page-product-characteristic {
    margin: 24px 0 80px;

    .btn-back-to {
        margin-bottom: 12px;
    }

    .header-info {
        display: flex;
        justify-content: space-between;
        width: fit-content;
        min-height: 62px;
        margin-bottom: 40px;
    }

    .col-12 {
        margin-top: 30px;

        &:first-child { margin: 0 };
    }

    .board {
        padding: 24px;
        border-radius: $border-radius-secondary;
        background: var(--color-component-bg-primary);
        box-shadow: var(--box-shadow-primary);
        position: relative;

        .app-error {
            margin-top: 16px;
        }

        .app-input,
        .app-dropdown-select {
            margin: 24px 0 0;
        }

        &>.btn,
        &+.btn {
            margin-top: 32px;
        }
    }

    &.on-sidebar {
        .app-loader {
            top: -4px;
        }
    }
}

@media (max-width: $tablet-size) {
    #page-product-characteristic {
        .header-info {
            width: 100%;
            margin-bottom: 48px;
            min-height: 56px;
        }

        .board {
            padding: 16px;
        }
    }
}

@media (max-width: $mobile-size) {
    #page-product-characteristic {
        margin: 24px 0 64px;

        .btn-back-to {
            font-size: 16px;
            line-height: 18px;
            margin-bottom: 8px;
        }

        .header-info {
            flex-direction: column;
            margin-bottom: 32px;
            min-height: 40px;
        }

        .board {
            padding: 16px 8px 8px;

            &>.btn {
                max-width: 100%;
            }

            &+.btn {
                margin-top: 15px;
            }
        }
    }
}
</style>