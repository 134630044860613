<template>
    <div id="page-product-price" :class="{ 'on-sidebar': on_sidebar }">
        <app-loader v-if="is_loading" fixed-on="desktop" />

        <div class="container">
            <div class="row" v-if="!on_sidebar">
                <div class="col-12">
                    <router-link class="btn btn-back-to" :to="product_route">Back to Product</router-link>

                    <div class="header-info">
                        <h1 class="heading"><span v-if="is_new">Adding</span> Product <span v-if="product.Name">"{{ product.Name }}"</span> Price <span v-if="original.Name">"{{ original.Name }}"</span></h1>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-mob-12"
                    :class="on_sidebar ? 'col-12' : 'col-6'"
                >
                    <app-error v-model="errors.save" />

                    <div class="board">
                        <h3 class="heading">Details</h3>

                        <!-- @todo :disabled="disabled || !is_new" -->
                        <app-dropdown-select
                            v-model="price.provider"
                            :options="dictonaries.providers"

                            label="Service Provider"

                            :predictive-search="true"

                            :required="true"

                            :error="errors.provider"
                            :disabled="disabled"

                            @change="errors.provider = null"
                        />

                        <app-dropdown-select       v-model="price.available"    label="Available"                          :options="dictonaries.bool"      :error="errors.available"   @change="errors.available = null"   :disabled="disabled"></app-dropdown-select>
                        <app-input                 v-model="price.price"        label="Price"                               type="tel"                      :error="errors.price"       @change="errors.price = null"       :disabled="disabled" :maxlength="8"></app-input>
                        <app-input-datetime-picker v-model="price.valid_from"   label="Valid From"                                                          :error="errors.valid_from"  @change="errors.valid_from = null"  :disabled="disabled"></app-input-datetime-picker>
                        <app-input-datetime-picker v-model="price.purchase_to"  label="Purchase Valid To"                                                   :error="errors.purchase_to" @change="errors.purchase_to = null" :disabled="disabled"></app-input-datetime-picker>
                        <app-input-datetime-picker v-model="price.version_to"   label="Version Valid To"                                                    :error="errors.version_to"  @change="errors.version_to = null"  :disabled="disabled"></app-input-datetime-picker>
                        <app-input                 v-model="price.priority"     label="Priority"                            type="number"                   :error="errors.priority"    @change="errors.priority = null"    :disabled="disabled" :maxlength="4" :min="0"></app-input>
                        <app-input                 v-model="price.retail_name"  label="Retail Name"                                                         :error="errors.retail_name" @change="errors.retail_name = null" :disabled="disabled" :maxlength="100"></app-input>
                        <app-input                 v-model="price.retail_desc"  label="Retail Description"                                                  :error="errors.retail_desc" @change="errors.retail_desc = null" :disabled="disabled" :maxlength="250"></app-input>

                        <button class="btn btn-small btn-primary btn-save" @click="save" :disabled="disabled || !is_changed" v-if="!on_sidebar">Save</button>
                    </div>

                    <button class="btn btn-full-width btn-primary btn-save" @click="save" :disabled="disabled || !is_changed" v-if="on_sidebar">Save</button>
                </div>

                <div class="col-mob-12"
                    :class="on_sidebar ? 'col-12' : 'col-6'"
                    v-if="is_product_availability_characteristics_enabled"
                >
                    <div class="board">
                        <h3 class="heading">Override Characteristics</h3>

                        <div class="known-characteristics" v-if="have_known_characteristics">
                            <app-input v-for="(characteristic, index) in price.known_characteristics" :key="index"
                                v-model="characteristic.value"

                                :label="characteristic.label"
                            />
                        </div>

                        <div class="unknown-characteristics">
                            <div class="characteristic" v-for="(characteristic, index) in price.unknown_characteristics" :key="index">
                                <app-input
                                    v-model="characteristic.name"

                                    label="Name"
                                />

                                <app-input
                                    v-model="characteristic.value"

                                    label="Value"
                                />

                                <button
                                    class="btn btn-table-action action-secondary action-remove"
                                    @click="removeCharacteristic(index)"
                                ></button>
                            </div>

                            <div class="actions">
                                <button class="btn btn-primary btn-small" @click="addCharacteristic()">Add</button>
                            </div>
                        </div>

                        <button class="btn btn-small btn-primary btn-save" @click="save" :disabled="disabled || !is_changed" v-if="!on_sidebar">Save</button>
                    </div>

                    <button class="btn btn-full-width btn-primary btn-save" @click="save" :disabled="disabled || !is_changed" v-if="on_sidebar">Save</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appInput from '@/components/app-input'
import appDropdownSelect from '@/components/app-dropdown-select'
import appInputDatetimePicker from '@/components/app-input-datetime-picker'

import ProductPricesValidator from '@/validators/product-prices-validator'
import errMessage from '@/helpers/errMessage'

import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'

import test_override_characteristics from './test-override-characteristics.json'

const KNOWN_CHARACTERISTICS = {
    'ufb.TermLength':   { name: 'ufb.TermLength',   label: 'UFB Term Length (months)' },
    'cellular.APNList': { name: 'cellular.APNList', label: 'Cellular APN'             },
}

const EMPTY_PRICE = {
    provider:    null,
    available:   true,
    price:       null,
    valid_from:  null,
    purchase_to: null,
    version_to:  null,
    priority:    null,
    retail_name: null,
    retail_desc: null,

    known_characteristics:   [],
    unknown_characteristics: [],
}

export default {
    props: {
        uuid:         { type: String, default: null },
        priceUuid:    { type: String, default: null },
        productChars: { type: Array,  default() { return [] } },
    },

    components: {
        appLoader,
        appError,
        appInput,
        appDropdownSelect,
        appInputDatetimePicker,
    },

    data() {
        return {
            product: {},

            price: {},
            original: {},

            dictonaries: {
                bool: [
                    { id: true,  name: 'Yes' },
                    { id: false, name: 'No'  },
                ],

                providers: [],
            },

            loading:    false,
            processing: false,

            errors: {},

            on_sidebar: false,
        }
    },

    created() {
        this.init()
    },

    mounted() {
        this.on_sidebar = this.$parent.$el.classList.contains('app-sidebar-details')
    },

    methods: {
        addCharacteristic() {
            this.price.unknown_characteristics.push({
                name:  '',
                value: '',
            })
        },

        removeCharacteristic(index) {
            this.price.unknown_characteristics.splice(index, 1)
        },

        init() {
            this.loadProductPrice()
        },

        initPrice(price) {
            let known_characteristics = []
            for (let i = 0, len = this.productChars.length; i < len; i++) {
                if (this.productChars[i].name == '') {
                    continue
                }
                if (this.productChars[i].name in KNOWN_CHARACTERISTICS) {
                    known_characteristics[this.productChars[i].name] = cloneDeep(KNOWN_CHARACTERISTICS[this.productChars[i].name])
                }
            }

            let unknown_characteristics = {}

            if (price) {
                if (price.OverrideCharacteristics) {
                    for (const key in price.OverrideCharacteristics) {
                        const characteristicName = key
                        const characteristicValue = price.OverrideCharacteristics[key]

                        if (characteristicName in known_characteristics) {
                            known_characteristics[characteristicName].name = characteristicName
                            known_characteristics[characteristicName].value = characteristicValue
                        } else if (characteristicName in KNOWN_CHARACTERISTICS) {
                            known_characteristics[characteristicName] = cloneDeep(KNOWN_CHARACTERISTICS[characteristicName])
                            known_characteristics[characteristicName].name = characteristicName
                            known_characteristics[characteristicName].value = characteristicValue
                        } else {
                            unknown_characteristics[characteristicName] = {
                                name: characteristicName,
                                value: characteristicValue,
                            }
                        }
                    }
                }

                this.price = {
                    provider:    price.SPID,
                    available:   price.Available,
                    price:       (price.PriceOverride / 100).toFixed(2),
                    valid_from:  price.ValidFromNanos ? new Date(price.ValidFromNanos / 1000000) : null,
                    purchase_to: price.PurchaseValidToNanos ? new Date(price.PurchaseValidToNanos / 1000000) : null,
                    version_to:  price.VersionValidToNanos ? new Date(price.VersionValidToNanos / 1000000) : null,
                    priority:    price.Priority,
                    retail_name: price.RetailName,
                    retail_desc: price.RetailDescription,

                    known_characteristics: Object.values(known_characteristics),
                    unknown_characteristics: Object.values(unknown_characteristics),
                }
                for (const key in this.price) {
                    if (this.price[key] instanceof Date) {
                        this.price[key].setSeconds(0, 0)
                    }
                }
            } else {
                /**
                 * RESET
                 */
                this.price = {
                    ...cloneDeep(EMPTY_PRICE),

                    known_characteristics: Object.values(known_characteristics),
                    unknown_characteristics: Object.values(unknown_characteristics),
                }
            }

            this.original = cloneDeep(this.price)
        },

        initPayload() {
            let payload = {
                UUID: this.price_uuid,
                SPID: this.price.provider,
                Available: Boolean(this.price.available),
                PriceOverride: this.price.price ? Math.round(this.price.price * 100) : null,
                ValidFromNanos: this.price.valid_from instanceof Date ? this.price.valid_from.getTime() * 1000000 : null,
                PurchaseValidToNanos: this.price.purchase_to instanceof Date ? this.price.purchase_to.getTime() * 1000000 : null,
                VersionValidToNanos: this.price.version_to instanceof Date ? this.price.version_to.getTime() * 1000000 : null,
                Priority: +this.price.priority,
                RetailName: this.price.retail_name,
                RetailDescription: this.price.retail_desc,
                ProductCatalogEntryUUID: this.prod_uuid,
            }

            if (this.is_product_availability_characteristics_enabled) {
                payload.OverrideCharacteristics = {}
                console.log('initPayload this.price.unknown_characteristics', this.price.unknown_characteristics)
                if (this.price.unknown_characteristics) {
                    for (const key in this.price.unknown_characteristics) {
                        if (!key || !this.price.unknown_characteristics[key].name || this.price.unknown_characteristics[key].value == null) {
                            continue
                        }
                        payload.OverrideCharacteristics[this.price.unknown_characteristics[key].name] = this.price.unknown_characteristics[key].value
                    }
                }
                console.log('initPayload this.price.known_characteristics', this.price.known_characteristics)
                if (this.price.known_characteristics) {
                    for (const key in this.price.known_characteristics) {
                        if (!key || !this.price.known_characteristics[key].name || this.price.known_characteristics[key].value == null) {
                            continue
                        }
                        payload.OverrideCharacteristics[this.price.known_characteristics[key].name] = this.price.known_characteristics[key].value
                    }
                }
            }

            for (const key in payload) {
                if (payload[key] == null) {
                    delete payload[key]
                }
            }

            return payload
        },

        loadProductPrice() {
            const loading = `${ this.prod_uuid } - ${ this.price_uuid }`

            if (this.loading != loading) {
                this.loading = loading
                this.processing = false
                this.errors = {}

                this.dictonaries.providers = this.available_service_providers
                    .map(provider => ({
                        id:   provider.SPID,
                        name: provider.Name + ' (' + provider.SPID + ')',
                    }))
                    .sort((a, b) => a.name.localeCompare(b.name))

                if (this.loading == loading) {
                    return this.$store.dispatch('api_product_catalog/getCachedProductCatalogEntryByUUID', { UUID: this.prod_uuid })
                        .then(product => {
                            if (this.loading == loading) {
                                this.product = product

                                if (this.is_new) {
                                    this.initPrice()

                                    this.loading = false
                                } else {
                                    return this.$store.dispatch('api_product_catalog/getCachedProductCatalogAvailabilityByUUID', { UUID: this.price_uuid })
                                        .then(price => {
                                            if (this.loading == loading) {
                                                if (price.ProductCatalogEntryUUID == this.prod_uuid) {
                                                    this.initPrice(price)

                                                    this.loading = false
                                                } else {
                                                    this.exit(this.product_route)
                                                }
                                            }
                                        })
                                        .catch(error => {
                                            if (this.loading == loading) {
                                                this.$store.dispatch('addToast', {
                                                    message: errMessage(error),
                                                    type: 'error',
                                                    delay: 5000,
                                                })

                                                this.exit(this.product_route)
                                            }
                                        })
                                }
                            }

                            return Promise.resolve(product)
                        })
                        .catch(error => {
                            if (this.loading == loading) {
                                this.$store.dispatch('addToast', {
                                    message: errMessage(error),
                                    type: 'error',
                                    delay: 5000,
                                })

                                this.exit({ name: 'products' })
                            }
                        })
                }
            }
        },

        save() {
            if (this.is_changed) {
                if (this.validation()) {
                    const processing = `${ this.prod_uuid } - ${ this.price_uuid }`

                    if (this.processing != processing) {
                        this.processing = processing

                        if (this.is_new) {
                            this.add(processing)
                        } else {
                            this.update(processing)
                        }
                    }
                } else {
                    if (this.on_sidebar) {
                        this.$parent.$refs.body.scrollTo(0, 0)
                    } else {
                        window.scrollTo(0, 0)
                    }
                }
            }
        },

        add(processing) {
            const payload = this.initPayload()

            this.$store.dispatch('api_product_catalog/AddProductCatalogAvailability', payload)
                .then(price => {
                    if (this.processing == processing) {
                        this.initPrice(price)

                        if (this.on_sidebar) {
                            this.$emit('added', price)
                        } else {
                            this.exit({ name: this.$route.name, params: { ...this.$route.params, price_uuid: price.UUID } })
                        }

                        this.processing = false
                    }
                })
                .catch(error => {
                    this.$set(this.errors, 'save', errMessage(error))

                    this.processing = false
                })
        },

        update(processing) {
            const payload = this.initPayload()

            this.$store.dispatch('api_product_catalog/UpdateProductCatalogAvailability', payload)
                .then(price => {
                    if (this.processing == processing) {
                        this.initPrice(price)

                        if (this.on_sidebar) {
                            this.$emit('updated', price)
                        }

                        this.processing = false
                    }
                })
                .catch(error => {
                    this.$set(this.errors, 'save', errMessage(error))

                    this.processing = false
                })
        },

        validation() {
            let is_valid = true
            this.errors = {}

            const values = { ...this.price }

            ProductPricesValidator.mixin({
                provider: {
                    custom_message: 'Please, select Service Provider',
                },
            })

            for (const field in values) {
                if (ProductPricesValidator.isRuleExists(field)) {
                    const error = ProductPricesValidator.isInvalid(field, values[field])

                    if (error) {
                        this.$set(this.errors, field, error)
                        is_valid = false
                    }
                }
            }

            return is_valid
        },

        exit(route) {
            if (this.on_sidebar) {
                this.$emit('close-sidebar')
            } else if (route) {
                this.$router.push({ ...route, replace: true })
            }
        },
    },

    watch: {
        prod_uuid() {
            this.loadProductPrice()
        },

        price_uuid() {
            this.loadProductPrice()
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',
            'available_service_providers',
            'is_product_availability_characteristics_enabled',
        ]),

        prod_uuid() {
            return this.uuid || this.$route.params.uuid
        },

        price_uuid() {
            return this.priceUuid || this.$route.params.price_uuid
        },

        is_new() {
            return !this.price_uuid
        },

        is_changed() {
            return !isEqual(this.price, this.original)
        },

        is_loading() {
            return Boolean(this.loading) || Boolean(this.processing)
        },

        product_route() {
            return { name: 'product', params: { uuid: this.prod_uuid } }
        },

        disabled() {
            return Boolean(this.loading) || Boolean(this.processing)
        },

        have_known_characteristics() {
            return Array.isArray(this.price.known_characteristics)
                && this.price.known_characteristics.length > 0
        },
    },
}
</script>

<style lang="scss">
#page-product-price {
    margin: 24px 0 80px;

    .btn-back-to {
        margin-bottom: 12px;
    }

    .header-info {
        display: flex;
        justify-content: space-between;

        width: fit-content;
        min-height: 62px;

        margin-bottom: 40px;
    }

    .row {
        .col-mob-12 {
            margin-bottom: 32px;

            .btn-save {
                display: none;
            }

            &:last-child {
                margin-bottom: 0;

                .btn-save {
                    display: flex;
                }
            }
        }
    }

    .app-error {
        margin: 0 0 24px;
    }

    .board {
        position: relative;

        padding: 24px;

        background-color: var(--color-component-bg-primary);
        border-radius: $border-radius-secondary;
        box-shadow: var(--box-shadow-primary);

        .known-characteristics {
            margin-top: 24px;

            .app-input {
                margin-bottom: 24px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .unknown-characteristics {
            margin-top: 24px;

            .characteristic {
                display: flex;
                align-items: center;

                margin-bottom: 24px;

                .app-input {
                    margin-right: 16px;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .actions {
                display: flex;
                justify-content: flex-end;
            }
        }

        & > .app-input,
        & > .app-dropdown-select {
            margin: 24px 0 0;
        }

        & > .app-input-datetime-picker {
            margin: 30px 0 0;

            & + .app-input {
                margin-top: 32px;
            }
        }

        & > .btn,
        & + .btn {
            margin-top: 32px;
        }
    }

    &.on-sidebar {
        .app-loader {
            top: -4px;
        }
    }
}

@media (max-width: $tablet-size) {
    #page-product-price {
        .header-info {
            width: 100%;
            min-height: 56px;

            margin-bottom: 48px;
        }

        .board {
            padding: 16px;
        }
    }
}

@media (max-width: $mobile-size) {
    #page-product-price {
        margin: 24px 0 64px;

        .btn-back-to {
            margin-bottom: 8px;

            font-size: 16px;
            line-height: 18px;
        }

        .header-info {
            flex-direction: column;

            min-height: 40px;

            margin-bottom: 32px;
        }

        .board {
            padding: 16px 8px 8px;

            & > .btn {
                max-width: 100%;
            }

            & + .btn {
                margin-top: 15px;
            }
        }
    }
}
</style>