<template>
    <base-layout>
        <template v-slot:sidebar>
            <app-sidebar-details :title="sidebar_title" @close-sidebar="closeSidebar">
                <characteristic-details v-if="sidebar_characteristic"
                    :uuid="uuid"
                    :char-uuid="characteristics.selected.uuid"
                    
                    @added="onAddedCharacteristic"
                    @updated="onUpdatedCharacteristic"
                    @close-sidebar="closeSidebar"
                ></characteristic-details>

                <price-details v-else-if="sidebar_price"
                    :uuid="uuid"
                    :price-uuid="prices.selected.uuid"
                    :product-chars="characteristics_list"
                    
                    @added="onAddedPrice"
                    @updated="onUpdatedPrice"
                    @close-sidebar="closeSidebar"
                ></price-details>
            </app-sidebar-details>
        </template>

        <div id="page-product-creating">
            <app-loader v-if="loading.product" fixed-on="desktop" />

            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <router-link class="btn btn-back-to" :to="back_to">Back to Product Catalogue</router-link>

                        <div class="header-info">
                            <h1 class="heading"><span v-if="is_new">Adding</span> Product <span v-if="is_editing && original.Name">"{{ original.Name }}"</span></h1>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="board">
                            <h3 class="heading">Product information</h3>

                            <app-loader v-if="loading.product || processing.product" />

                            <app-error v-model="errors.product" />

                            <div class="input-line-dbl">
                                <app-input :readonly="readonlyMode" v-model="product.Name"        label="Plan name"         type="text" :required="true" :error="errors.Name"        @change="errors.Name = null"        :disabled="loading.product || processing.product" :maxlength="64"></app-input>
                                <app-input :value="product.ProductType" label="Plan type"         type="text"  readonly></app-input>
                            </div>

                            <app-input
                                v-model="product.Description"

                                label="Plan description"

                                :readonly="readonlyMode"

                                :disabled="loading.product || processing.product"
                                :error="errors.Description"

                                :maxlength="80"
                                :characters-counter="true"

                                @change="errors.Description = null"
                            />

                            <div class="input-line-dbl">
                                <app-dropdown-select :readonly="readonlyMode" v-model="product_characteristics['AllowCustomerPurchase'].DefaultValue"    label="Allow Customer Purchase"       :error="errors['AllowCustomerPurchase']"          @select="errors['AllowCustomerPurchase'] = null"          :disabled="loading.product || processing.data"                 v-if="show_characteristic('product','AllowCustomerPurchase')"
                                    :options="dictonaries.true_false"
                                ></app-dropdown-select>
                                <app-dropdown-select :readonly="readonlyMode" v-model="product_characteristics['CustomerVisible'].DefaultValue"    label="Product visible to Customers?"       :error="errors['CustomerVisible']"          @select="errors['CustomerVisible'] = null"          :disabled="loading.product || processing.data"                 v-if="show_characteristic('product','CustomerVisible')"
                                    :options="dictonaries.true_false"
                                ></app-dropdown-select>

                                <app-input v-if="show_characteristic('product','ocs.RecurringBaseCost')"
                                    v-model="product_characteristics['ocs.RecurringBaseCost'].DefaultValue"

                                    label="Default Plan monthly cost"

                                    :mask="masks['ocs.RecurringBaseCost']"

                                    :readonly="readonlyMode"

                                    :maxlength="8"

                                    :error="errors['ocs.RecurringBaseCost']"
                                    :disabled="loading.product || processing.product"

                                    @change="errors['ocs.RecurringBaseCost'] = null"
                                />

                                <app-dropdown-select :readonly="readonlyMode" v-model="product_characteristics['ocs.ESPReseller'].DefaultValue"    label="Enhanced Service Provider Mode"       :error="errors['ocs.ESPReseller']"          @select="errors['ocs.ESPReseller'] = null"          :disabled="loading.product || processing.product"                 v-if="show_characteristic('product','ocs.ESPReseller')"
                                    :options="dictonaries.true_false"
                                ></app-dropdown-select>
                            </div>

                            <div class="input-line-dbl">
                                <app-input :readonly="readonlyMode" v-model="product_characteristics['ocs.OfferPeriodCount'].DefaultValue" :label="is_prerelease_mode ? 'Offer Valid for Count' : 'Offer days'" type="tel"  :error="errors['ocs.OfferPeriodCount']" @change="errors['ocs.OfferPeriodCount'] = null" :disabled="loading.product || processing.product" :maxlength="3"   v-if="show_characteristic('product','ocs.OfferPeriodCount')"></app-input>
                                <app-dropdown-select :readonly="readonlyMode" v-model="product_characteristics['ocs.OfferPeriodInterval'].DefaultValue"    label="Offer Valid for Interval"       :error="errors['ocs.OfferPeriodInterval']"          @select="errors['ocs.OfferPeriodInterval'] = null"          :disabled="loading.product || processing.data"  v-if="show_characteristic('product','ocs.OfferPeriodInterval') && is_prerelease_mode"
                                    :options="dictonaries.days_months"
                                ></app-dropdown-select>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 characteristics">
                        <div class="board" v-if="show_characteristic_group('data')">
                            <h3 class="heading">Data</h3>

                            <app-loader v-if="loading.product || processing.data"></app-loader>

                            <app-error v-model="errors.data"></app-error>

                            <div class="input-line-dbl">
                                <app-input :readonly="readonlyMode" v-model="product_characteristics['ocs.PurchaseDataAllowance'].DefaultValue"  label="Initial Data MB on purchase" type="tel" :error="errors['ocs.PurchaseDataAllowance']"  @change="errors['ocs.PurchaseDataAllowance'] = null"  :disabled="loading.product || processing.data" :maxlength="10" v-if="show_characteristic('data','ocs.PurchaseDataAllowance')"></app-input>
                                <app-dropdown-select :readonly="readonlyMode" v-model="product_characteristics['ocs.DataUsageRule'].DefaultValue"    label="Action at data cap"         :error="errors['ocs.DataUsageRule']"          @select="errors['ocs.DataUsageRule'] = null"          :disabled="loading.product || processing.data"                 v-if="show_characteristic('data','ocs.DataUsageRule')"
                                    :options="dictonaries.cap_throttled"
                                ></app-dropdown-select>
                            </div>

                            <div class="input-line-dbl">
                                <app-input :readonly="readonlyMode" v-model="product_characteristics['ocs.RecurringDataAllowance'].DefaultValue" label="Monthly Data MB on renewal" type="tel"  :required="true" :error="errors['ocs.RecurringDataAllowance']" @change="errors['ocs.RecurringDataAllowance'] = null;" :disabled="loading.product || processing.data" :maxlength="10" v-if="show_characteristic('data','ocs.RecurringDataAllowance')"></app-input>
                                <app-input :readonly="readonlyMode" v-model="product_characteristics['cellular.APNList'].DefaultValue"           label="Customer APN"               type="text" :required="true" :error="errors['cellular.APNList']"           @change="errors['cellular.APNList'] = null"           :disabled="loading.product || processing.data" :maxlength="255" :mask="mask['cellular.APNList']" v-if="show_characteristic('data','cellular.APNList')"></app-input>
                                <app-input :readonly="readonlyMode" v-model="product_characteristics['FWA.ServiceAddress'].DefaultValue"         label="L2TP LNS Server"            type="text" :required="true" :error="errors['FWA.ServiceAddress']"           @change="errors['FWA.ServiceAddress'] = null"           :disabled="loading.product || processing.data" :maxlength="255" :mask="mask['FWA.ServiceAddress']" v-if="show_characteristic('data','FWA.ServiceAddress')"></app-input>
                            </div>

                            <div class="input-line-dbl">
                                <app-input :readonly="readonlyMode" v-model="product_characteristics['cellular.SpeedUp'].DefaultValue"  label="Cellular Uplink Speed" type="tel" :error="errors['cellular.SpeedUp']"  @change="errors['cellular.SpeedUp'] = null"  :disabled="loading.product || processing.data" :maxlength="10" v-if="show_characteristic('data','cellular.SpeedUp')"></app-input>
                                <app-input :readonly="readonlyMode" v-model="product_characteristics['cellular.SpeedDown'].DefaultValue" label="Cellular Downlink Speed" type="tel" :error="errors['cellular.SpeedDown']" @change="errors['cellular.SpeedDown'] = null;" :disabled="loading.product || processing.data" :maxlength="10" v-if="show_characteristic('data','cellular.SpeedDown')"></app-input>
                            </div>

                            <div class="input-line-dbl">
                                <app-dropdown-select
                                    v-if="show_characteristic('data', 'ocs.SpeedLimitDL')"

                                    v-model="product_characteristics['ocs.SpeedLimitDL'].DefaultValue"

                                    label="Speed Limit Download"

                                    :options="dictonaries.SpeedLimitDL"

                                    :readonly="readonlyMode"

                                    :error="errors['ocs.SpeedLimitDL']"
                                    :disabled="loading.product || processing.data"

                                    @change="errors['ocs.SpeedLimitDL'] = null"
                                />

                                <app-dropdown-select
                                    v-if="show_characteristic('data', 'ocs.SpeedLimitUL')"

                                    v-model="product_characteristics['ocs.SpeedLimitUL'].DefaultValue"

                                    label="Speed Limit Upload"

                                    :options="dictonaries.SpeedLimitUL"

                                    :readonly="readonlyMode"

                                    :error="errors['ocs.SpeedLimitUL']"
                                    :disabled="loading.product || processing.data"

                                    @change="errors['ocs.SpeedLimitUL'] = null"
                                />
                            </div>

                            <div class="input-line-dbl">
                                <app-dropdown-select :readonly="readonlyMode" v-model="product_characteristics['ocs.PrepaidDataRollOverAllowed'].DefaultValue"    label="Data Rollover Allowed (Prepay)"         :error="errors['ocs.PrepaidDataRollOverAllowed']"          @select="errors['ocs.PrepaidDataRollOverAllowed'] = null"          :disabled="loading.product || processing.data"                 v-if="show_characteristic('data','ocs.PrepaidDataRollOverAllowed')"
                                    :options="dictonaries.true_false"
                                ></app-dropdown-select>
                                <app-input :readonly="readonlyMode" v-model="product_characteristics['ocs.MaxUnusedDataRollOver'].DefaultValue"  label="Max Rollover Data MB" type="tel" :error="errors['ocs.MaxUnusedDataRollOver']" @change="errors['ocs.MaxUnusedDataRollOver'] = null" :disabled="loading.product || processing.data" :maxlength="10" v-if="show_characteristic('data','ocs.MaxUnusedDataRollOver') && product_characteristics['ocs.PrepaidDataRollOverAllowed'].DefaultValue == 'true'"></app-input>
                            </div>

                            <div class="btns">
                                <button class="btn btn-small btn-primary" @click="save('data')" :disabled="loading.product || processing.data || !is_changed.data" v-if="is_editing && !readonlyMode">Save</button>
                            </div>
                        </div>

                        <div class="board" v-if="show_characteristic_group('mobile_esp')">
                            <h3 class="heading">Call &amp; Data Barring</h3>

                            <app-loader v-if="loading.product || processing.mobile_esp" />

                            <app-error v-model="errors.mobile_esp" />

                            <div class="input-line-dbl">
                                <app-dropdown-select :readonly="readonlyMode" v-model="product_characteristics['ocs.BlockPremiumCalls'].DefaultValue"    label="Block Premium Calls?"       :error="errors['ocs.BlockPremiumCalls']"          @select="errors['ocs.BlockPremiumCalls'] = null"          :disabled="loading.product || processing.mobile_esp"                 v-if="show_characteristic('mobile_esp','ocs.BlockPremiumCalls')"
                                    :options="dictonaries.true_false"
                                ></app-dropdown-select>
                                <app-input :readonly="readonlyMode" v-model="product_characteristics['ocs.PremiumCallsBarred'].DefaultValue"  label="Premium Calls List to Block (CSV List)" type="tel" :error="errors['ocs.PremiumCallsBarred']" @change="errors['ocs.PremiumCallsBarred'] = null" :disabled="loading.product || processing.data" :maxlength="10" v-if="show_characteristic('mobile_esp','ocs.PremiumCallsBarred')"></app-input>
                            </div>

                            <div class="input-line-dbl">
                                <app-dropdown-select :readonly="readonlyMode" v-model="product_characteristics['ocs.BlockPremiumSMS'].DefaultValue"    label="Block Premium SMS?"       :error="errors['ocs.BlockPremiumSMS']"          @select="errors['ocs.BlockPremiumSMS'] = null"          :disabled="loading.product || processing.mobile_esp"                 v-if="show_characteristic('mobile_esp','ocs.BlockPremiumSMS')"
                                    :options="dictonaries.true_false"
                                ></app-dropdown-select>
                                <app-input :readonly="readonlyMode" v-model="product_characteristics['ocs.PremiumSMSBarred'].DefaultValue"  label="Premium SMS List to Block (CSV List)" type="tel" :error="errors['ocs.PremiumSMSBarred']" @change="errors['ocs.PremiumSMSBarred'] = null" :disabled="loading.product || processing.data" :maxlength="10" v-if="show_characteristic('mobile_esp','ocs.PremiumSMSBarred')"></app-input>
                            </div>

                            <div class="input-line-dbl">
                                <app-dropdown-select :readonly="readonlyMode" v-model="product_characteristics['ocs.OutgoingIDDBarred'].DefaultValue"    label="Outgoing IDD Calls Barred"       :error="errors['ocs.OutgoingIDDBarred']"          @select="errors['ocs.OutgoingIDDBarred'] = null"          :disabled="loading.product || processing.mobile_esp"                 v-if="show_characteristic('mobile_esp','ocs.OutgoingIDDBarred')"
                                    :options="dictonaries.true_false"
                                ></app-dropdown-select>
                                <app-dropdown-select :readonly="readonlyMode" v-model="product_characteristics['ocs.OutgoingTextBarred'].DefaultValue"    label="Outgoing Text Barred"       :error="errors['ocs.OutgoingTextBarred']"          @select="errors['ocs.OutgoingTextBarred'] = null"          :disabled="loading.product || processing.mobile_esp"                 v-if="show_characteristic('mobile_esp','ocs.OutgoingTextBarred')"
                                    :options="dictonaries.true_false"
                                ></app-dropdown-select>
                            </div>

                            <div class="input-line-dbl">
                                <app-dropdown-select :readonly="readonlyMode" v-model="product_characteristics['ocs.RoamingBarred'].DefaultValue"    label="Roaming Barred"       :error="errors['ocs.RoamingBarred']"          @select="errors['ocs.RoamingBarred'] = null"          :disabled="loading.product || processing.mobile_esp"                 v-if="show_characteristic('mobile_esp','ocs.RoamingBarred')"
                                    :options="dictonaries.true_false"
                                ></app-dropdown-select>
                            </div>

                            <div class="input-line-dbl">
                                <app-dropdown-select :readonly="readonlyMode" v-model="product_characteristics['ocs.InCallsWhileRoamingBarred'].DefaultValue"    label="Inbound Roaming calls Barred"       :error="errors['ocs.InCallsWhileRoamingBarred']"          @select="errors['ocs.InCallsWhileRoamingBarred'] = null"          :disabled="loading.product || processing.mobile_esp"                 v-if="show_characteristic('mobile_esp','ocs.InCallsWhileRoamingBarred')"
                                    :options="dictonaries.yes_no"
                                ></app-dropdown-select>
                                <app-dropdown-select :readonly="readonlyMode" v-model="product_characteristics['ocs.OutCallsWhileRoamingBarred'].DefaultValue"    label="Outgoing Roaming calls Barred"       :error="errors['ocs.OutCallsWhileRoamingBarred']"          @select="errors['ocs.OutCallsWhileRoamingBarred'] = null"          :disabled="loading.product || processing.mobile_esp"                 v-if="show_characteristic('mobile_esp','ocs.OutCallsWhileRoamingBarred')"
                                    :options="dictonaries.yes_no"
                                ></app-dropdown-select>
                            </div>

                            <div class="input-line-dbl">
                                <app-dropdown-select :readonly="readonlyMode" v-model="product_characteristics['ocs.OOBWhileRoamingBarred'].DefaultValue"    label="Out-of-bundle Roaming calls Barred"       :error="errors['ocs.OOBWhileRoamingBarred']"          @select="errors['ocs.OOBWhileRoamingBarred'] = null"          :disabled="loading.product || processing.mobile_esp"                 v-if="show_characteristic('mobile_esp','ocs.OOBWhileRoamingBarred')"
                                    :options="dictonaries.yes_no"
                                ></app-dropdown-select>
                                <app-dropdown-select :readonly="readonlyMode" v-model="product_characteristics['ocs.ChargeableDataRGBarred'].DefaultValue"    label="Chargable Roaming data Barred"       :error="errors['ocs.ChargeableDataRGBarred']"          @select="errors['ocs.ChargeableDataRGBarred'] = null"          :disabled="loading.product || processing.mobile_esp"                 v-if="show_characteristic('mobile_esp','ocs.ChargeableDataRGBarred')"
                                    :options="dictonaries.yes_no"
                                ></app-dropdown-select>
                            </div>


                            <div class="btns">
                                <button class="btn btn-small btn-primary" @click="save('mobile_esp')" :disabled="loading.product || processing.mobile_esp || !is_changed.mobile_esp" v-if="is_editing && !readonlyMode">Save</button>
                            </div>
                        </div>

                        <div class="board" v-if="show_characteristic_group('throttling_speed_limits')">
                            <h3 class="heading">Throttling Speed Limits</h3>

                            <app-loader v-if="loading.product || processing.throttling_speed_limits" />

                            <app-error v-model="errors.throttling_speed_limits" />

                            <div class="input-line-dbl">
                                <app-dropdown-select
                                    v-if="show_characteristic('throttling_speed_limits', 'ocs.Throttle')"

                                    v-model="product_characteristics['ocs.Throttle'].DefaultValue"

                                    label="Throttle"

                                    :options="dictonaries.Throttle"

                                    :readonly="readonlyMode"

                                    :error="errors['ocs.Throttle']"
                                    :disabled="loading.product || processing.throttling_speed_limits"

                                    @change="errors['ocs.Throttle'] = null"
                                />

                                <app-dropdown-select
                                    v-if="show_characteristic('throttling_speed_limits', 'ocs.Tethering')"

                                    v-model="product_characteristics['ocs.Tethering'].DefaultValue"

                                    label="Tethering"

                                    :options="dictonaries.true_false"

                                    :readonly="readonlyMode"

                                    :error="errors['ocs.Tethering']"
                                    :disabled="loading.product || processing.throttling_speed_limits"

                                    @change="errors['ocs.Tethering'] = null"
                                />
                            </div>

                            <div class="btns">
                                <button class="btn btn-small btn-primary" @click="save('throttling_speed_limits')" :disabled="loading.product || processing.throttling_speed_limits || !is_changed.throttling_speed_limits" v-if="is_editing && !readonlyMode">Save</button>
                            </div>
                        </div>

                        <div class="board" v-if="show_characteristic_group('voice')">
                            <h3 class="heading">Voice</h3>

                            <app-loader v-if="loading.product || processing.voice"></app-loader>

                            <app-error v-model="errors.voice"></app-error>

                            <div class="input-line-dbl">
                                <app-input :readonly="readonlyMode" v-model="product_characteristics['ocs.PurchaseVoiceAllowance'].DefaultValue"  label="Initial Voice mins on purchase" type="tel" :error="errors['ocs.PurchaseVoiceAllowance']"  @change="errors['ocs.PurchaseVoiceAllowance'] = null" :disabled="loading.product || processing.voice" :maxlength="5" v-if="show_characteristic('voice','ocs.PurchaseVoiceAllowance')"></app-input>
                                <app-dropdown-select :readonly="readonlyMode" v-model="product_characteristics['ocs.VoiceUsageRule'].DefaultValue"    label="Action at voice cap"         :error="errors['ocs.VoiceUsageRule']"          @select="errors['ocs.VoiceUsageRule'] = null"          :disabled="loading.product || processing.voice"                 v-if="show_characteristic('voice','ocs.VoiceUsageRule')"
                                    :options="dictonaries.cap"
                                ></app-dropdown-select>
                            </div>

                            <div class="input-line-dbl">
                                <app-input :readonly="readonlyMode" v-model="product_characteristics['ocs.RecurringVoiceAllowance'].DefaultValue" label="Monthly Voice mins on renewal" type="tel" :error="errors['ocs.RecurringVoiceAllowance']" @change="errors['ocs.RecurringVoiceAllowance'] = null" :disabled="loading.product || processing.voice" :maxlength="5" v-if="show_characteristic('voice','ocs.RecurringVoiceAllowance')"></app-input>
                                <app-dropdown-select :readonly="readonlyMode" v-model="product_characteristics['ocs.PrepaidCUGUsageRule'].DefaultValue"    label="Calls in Closed User Group"         :error="errors['ocs.PrepaidCUGUsageRule']"          @select="errors['ocs.PrepaidCUGUsageRule'] = null"          :disabled="loading.product || processing.voice"                 v-if="show_characteristic('voice','ocs.PrepaidCUGUsageRule')"
                                    :options="dictonaries.PrepaidCUGUsageRule"
                                ></app-dropdown-select>
                            </div>

                            <div class="input-line-dbl">
                                <app-dropdown-select :readonly="readonlyMode" v-model="product_characteristics['ocs.PrepaidVoiceRollOverAllowed'].DefaultValue"    label="Voice Rollover Allowed (Prepay)"         :error="errors['ocs.PrepaidVoiceRollOverAllowed']"          @select="errors['ocs.PrepaidVoiceRollOverAllowed'] = null"          :disabled="loading.product || processing.voice"                 v-if="show_characteristic('voice','ocs.PrepaidVoiceRollOverAllowed')"
                                    :options="dictonaries.true_false"
                                ></app-dropdown-select>
                                <app-input :readonly="readonlyMode" v-model="product_characteristics['ocs.MaxUnusedVoiceRollOver'].DefaultValue"  label="Max Rollover Voice Mins" type="tel" :error="errors['ocs.MaxUnusedVoiceRollOver']" @change="errors['ocs.MaxUnusedVoiceRollOver'] = null" :disabled="loading.product || processing.voice" :maxlength="10" v-if="show_characteristic('voice','ocs.MaxUnusedVoiceRollOver') && product_characteristics['ocs.PrepaidVoiceRollOverAllowed'].DefaultValue == 'true'"></app-input>
                            </div>


                            <div class="input-line-dbl">
                                <app-input
                                    v-if="show_characteristic('voice','cellular.OutgoingCallerID')"

                                    v-model="product_characteristics['cellular.OutgoingCallerID'].DefaultValue"

                                    label="Outgoing caller id"

                                    :readonly="readonlyMode"

                                    :error="errors['cellular.OutgoingCallerID']"
                                    :disabled="loading.product || processing.voice"

                                    @change="errors['cellular.OutgoingCallerID'] = null"
                                />

                                <app-dropdown-select
                                    v-if="show_characteristic('voice','cellular.IncomingCallerID')"

                                    v-model="product_characteristics['cellular.IncomingCallerID'].DefaultValue"

                                    label="Incoming caller id"

                                    :options="dictonaries.true_false"

                                    :readonly="readonlyMode"

                                    :error="errors['cellular.IncomingCallerID']"
                                    :disabled="loading.product || processing.voice"

                                    @select="errors['cellular.IncomingCallerID'] = null"
                                />
                            </div>

                            <div class="input-line-dbl">
                                <app-dropdown-select
                                    v-if="show_characteristic('voice','cellular.AllowCallHold')"

                                    v-model="product_characteristics['cellular.AllowCallHold'].DefaultValue"

                                    label="Allow call hold"

                                    :options="dictonaries.true_false"

                                    :readonly="readonlyMode"

                                    :error="errors['cellular.AllowCallHold']"
                                    :disabled="loading.product || processing.voice"

                                    @select="errors['cellular.AllowCallHold'] = null"
                                />
                            </div>

                            <div class="btns">
                                <button class="btn btn-small btn-primary" @click="save('voice')" :disabled="loading.product || processing.voice || !is_changed.voice" v-if="is_editing && !readonlyMode">Save</button>
                            </div>
                        </div>

                        <div class="board" v-if="show_characteristic_group('sms')">
                            <h3 class="heading">SMS</h3>

                            <app-loader v-if="loading.product || processing.sms"></app-loader>

                            <app-error v-model="errors.sms"></app-error>

                            <div class="input-line-dbl">
                                <app-input :readonly="readonlyMode" v-model="product_characteristics['ocs.PurchaseSMSAllowance'].DefaultValue"         label="Initial SMS on purchase" type="tel"                  :error="errors['ocs.PurchaseSMSAllowance']"         @change="errors['ocs.PurchaseSMSAllowance'] = null"         :disabled="loading.product || processing.sms" :maxlength="7" v-if="show_characteristic('sms','ocs.PurchaseSMSAllowance')"></app-input>
                                <app-dropdown-select :readonly="readonlyMode" v-model="product_characteristics['ocs.SMSUsageRule'].DefaultValue"    label="Action at SMS cap"         :error="errors['ocs.SMSUsageRule']"          @select="errors['ocs.SMSUsageRule'] = null"          :disabled="loading.product || processing.sms"                 v-if="show_characteristic('sms','ocs.SMSUsageRule')"
                                    :options="dictonaries.cap"
                                ></app-dropdown-select>
                            </div>

                            <div class="input-line-dbl">
                                <app-input :readonly="readonlyMode" v-model="product_characteristics['ocs.RecurringSMSAllowance'].DefaultValue"        label="Monthly SMS on renewal" type="tel"                  :error="errors['ocs.RecurringSMSAllowance']"        @change="errors['ocs.RecurringSMSAllowance'] = null"        :disabled="loading.product || processing.sms" :maxlength="7" v-if="show_characteristic('sms','ocs.RecurringSMSAllowance')"></app-input>
                            </div>

                            <div class="input-line-dbl">
                                <app-dropdown-select :readonly="readonlyMode" v-model="product_characteristics['ocs.PrepaidSMSRollOverAllowed'].DefaultValue"    label="SMS Rollover Allowed (Prepay)"         :error="errors['ocs.PrepaidSMSRollOverAllowed']"          @select="errors['ocs.PrepaidSMSRollOverAllowed'] = null"          :disabled="loading.product || processing.sms"                 v-if="show_characteristic('sms','ocs.PrepaidSMSRollOverAllowed')"
                                    :options="dictonaries.true_false"
                                ></app-dropdown-select>
                                <app-input :readonly="readonlyMode" v-model="product_characteristics['ocs.MaxUnusedSMSRollOver'].DefaultValue"  label="Max Rollover SMS" type="tel" :error="errors['ocs.MaxUnusedSMSRollOver']" @change="errors['ocs.MaxUnusedSMSRollOver'] = null" :disabled="loading.product || processing.data" :maxlength="10" v-if="show_characteristic('sms','ocs.MaxUnusedSMSRollOver') && product_characteristics['ocs.PrepaidSMSRollOverAllowed'].DefaultValue == 'true'"></app-input>
                            </div>

                            <div class="btns">
                                <button class="btn btn-small btn-primary" @click="save('sms')" :disabled="loading.product || processing.sms || !is_changed.sms" v-if="is_editing && !readonlyMode">Save</button>
                            </div>
                        </div>

                        <div class="board" v-if="show_characteristic_group('mms')">
                            <h3 class="heading">MMS</h3>

                            <app-loader v-if="loading.product || processing.mms"></app-loader>

                            <app-error v-model="errors.mms"></app-error>

                            <div class="input-line-dbl">
                                <app-input :readonly="readonlyMode" v-model="product_characteristics['ocs.PurchaseMMSAllowance'].DefaultValue"  label="Initial MMS on purchase" type="tel" :error="errors['ocs.PurchaseMMSAllowance']"  @change="errors['ocs.PurchaseMMSAllowance'] = null"  :disabled="loading.product || processing.mms" :maxlength="7" v-if="show_characteristic('mms','ocs.PurchaseMMSAllowance')"></app-input>
                                <app-dropdown-select :readonly="readonlyMode" v-model="product_characteristics['ocs.MMSUsageRule'].DefaultValue"    label="Action at MMS cap"         :error="errors['ocs.MMSUsageRule']"          @select="errors['ocs.MMSUsageRule'] = null"          :disabled="loading.product || processing.mms"                 v-if="show_characteristic('mms','ocs.MMSUsageRule')"
                                    :options="dictonaries.cap"
                                ></app-dropdown-select>
                            </div>

                            <div class="input-line-dbl">
                                <app-input :readonly="readonlyMode" v-model="product_characteristics['ocs.RecurringMMSAllowance'].DefaultValue" label="Monthly MMS on renewal" type="tel" :error="errors['ocs.RecurringMMSAllowance']" @change="errors['ocs.RecurringMMSAllowance'] = null" :disabled="loading.product || processing.mms" :maxlength="7" v-if="show_characteristic('mms','ocs.RecurringMMSAllowance')"></app-input>
                            </div>

                            <div class="input-line-dbl">
                                <app-dropdown-select :readonly="readonlyMode" v-model="product_characteristics['ocs.PrepaidMMSRollOverAllowed'].DefaultValue"    label="MMS Rollover Allowed (Prepay)"         :error="errors['ocs.PrepaidMMSRollOverAllowed']"          @select="errors['ocs.PrepaidMMSRollOverAllowed'] = null"          :disabled="loading.product || processing.mms"                 v-if="show_characteristic('mms','ocs.PrepaidMMSRollOverAllowed')"
                                    :options="dictonaries.true_false"
                                ></app-dropdown-select>
                                <app-input :readonly="readonlyMode" v-model="product_characteristics['ocs.MaxUnusedMMSRollOver'].DefaultValue"  label="Max Rollover MMS" type="tel" :error="errors['ocs.MaxUnusedMMSRollOver']" @change="errors['ocs.MaxUnusedMMSRollOver'] = null" :disabled="loading.product || processing.data" :maxlength="10" v-if="show_characteristic('mms','ocs.MaxUnusedMMSRollOver') && product_characteristics['ocs.PrepaidMMSRollOverAllowed'].DefaultValue == 'true'"></app-input>
                            </div>

                            <div class="btns">
                                <button class="btn btn-small btn-primary" @click="save('mms')" :disabled="loading.product || processing.mms || !is_changed.mms" v-if="is_editing && !readonlyMode">Save</button>
                            </div>
                        </div>

                        <div class="board" v-if="show_characteristic_group('prepay_credit')">
                            <h3 class="heading">Prepay Credit Details</h3>

                            <app-loader v-if="loading.product || processing.prepay_credit"></app-loader>

                            <app-error v-model="errors.prepay_credit"></app-error>

                            <div class="input-line-dbl" v-if="is_prerelease_mode">
                                <app-dropdown-select :readonly="readonlyMode" v-model="product_characteristics['ocs.PrepaidCreditExpiryDays'].DefaultValue"    label="Credit Expires after"      :error="errors.PrepaidCreditExpiryDays"          @select="errors.PrepaidCreditExpiryDays = null"          :disabled="loading.product || processing.mms"                v-if="show_characteristic('prepay_credit','ocs.PrepaidCreditExpiryDays')"
                                    :options="dictonaries.PrepaidCreditExpiryDays"
                                ></app-dropdown-select>
                                <app-dropdown-select :readonly="readonlyMode" v-model="product_characteristics['ocs.PrepaidRollOverExpiryDays'].DefaultValue"    label="Rollver Expires after"      :error="errors.PrepaidRollOverExpiryDays"          @select="errors.PrepaidRollOverExpiryDays = null"          :disabled="loading.product || processing.mms"                v-if="show_characteristic('prepay_credit','ocs.PrepaidRollOverExpiryDays')"
                                    :options="dictonaries.PrepaidRollOverExpiryDays"
                                ></app-dropdown-select>
                            </div>

                            <div class="input-line-dbl">
                                <app-input :readonly="readonlyMode" v-model="product_characteristics['ocs.PurchaseCreditAmount'].DefaultValue"  label="Credit Granted on Purchase" type="tel" :error="errors['ocs.PurchaseCreditAmount']" @change="errors['ocs.PurchaseCreditAmount'] = null" :disabled="loading.product || processing.data" :maxlength="10" v-if="show_characteristic('prepay_credit','ocs.PurchaseCreditAmount')"></app-input>
                                <app-input :readonly="readonlyMode" v-model="product_characteristics['ocs.RecurringCreditAmount'].DefaultValue"  label="Credit Granted on Renewal" type="tel" :error="errors['ocs.RecurringCreditAmount']" @change="errors['ocs.RecurringCreditAmount'] = null" :disabled="loading.product || processing.data" :maxlength="10" v-if="show_characteristic('prepay_credit','ocs.RecurringCreditAmount')"></app-input>
                            </div>

                            <div class="btns">
                                <button class="btn btn-small btn-primary" @click="save('prepay_credit')" :disabled="loading.product || processing.prepay_credit || !is_changed.prepay_credit" v-if="is_editing && !readonlyMode">Save</button>
                            </div>
                        </div>

                        <div class="board" v-if="show_characteristic_group('prepay_purchase') && is_prerelease_mode">
                            <h3 class="heading">Prepay Purchasing Rules</h3>

                            <app-loader v-if="loading.product || processing.prepay_purchase"></app-loader>

                            <app-error v-model="errors.prepay_purchase"></app-error>

                            <div class="input-line-dbl">
                                <app-dropdown-select :readonly="readonlyMode" v-model="product_characteristics['IsBasePlan'].DefaultValue"    label="Is Base Plan?"       :error="errors['IsBasePlan']"          @select="errors['IsBasePlan'] = null"          :disabled="loading.product || processing.data"                 v-if="show_characteristic('prepay_purchase','IsBasePlan')"
                                    :options="dictonaries.true_false"
                                ></app-dropdown-select>
                                <app-dropdown-select :readonly="readonlyMode" v-model="product_characteristics['AllowAutoRenew'].DefaultValue"    label="Allow Auto-Renew"       :error="errors['AllowAutoRenew']"          @select="errors['AllowAutoRenew'] = null"          :disabled="loading.product || processing.data"                 v-if="show_characteristic('prepay_purchase','AllowAutoRenew')"
                                    :options="dictonaries.true_false"
                                ></app-dropdown-select>
                            </div>

                            <div class="input-line-dbl">
                                <app-dropdown-select :readonly="readonlyMode" v-model="product_characteristics['RequiresAnyBasePlan'].DefaultValue"    label="Purchase Requires Base Plan"       :error="errors['RequiresAnyBasePlan']"          @select="errors['RequiresAnyBasePlan'] = null"          :disabled="loading.product || processing.data"                 v-if="show_characteristic('prepay_purchase','RequiresAnyBasePlan')"
                                    :options="dictonaries.true_false"
                                ></app-dropdown-select>
                                <app-dropdown-select :readonly="readonlyMode" v-model="product_characteristics['RequiresNoBasePlan'].DefaultValue"     label="Purchase Requires No Base Plan"    :error="errors['RequiresNoBasePlan']"          @select="errors['RequiresNoBasePlan'] = null"          :disabled="loading.product || processing.data"                 v-if="show_characteristic('prepay_purchase','RequiresNoBasePlan')"
                                    :options="dictonaries.true_false"
                                ></app-dropdown-select>
                            </div>

                            <div class="btns">
                                <button class="btn btn-small btn-primary" @click="save('prepay_purchase')" :disabled="loading.product || processing.prepay_purchase || !is_changed.prepay_purchase" v-if="is_editing && !readonlyMode">Save</button>
                            </div>
                        </div>

                        <div class="board" v-else-if="show_characteristic_group('plan_ufb')">
                            <h3 class="heading">UFB Plan Settings</h3>

                            <app-loader v-if="loading.product || processing.plan_ufb"></app-loader>

                            <app-error v-model="errors.plan_ufb"></app-error>

                            <div class="input-line-dbl">
                                <app-input :readonly="readonlyMode" v-model="product_characteristics['ufb.TermLength'].DefaultValue"  label="Default Term Length (months)" :mask="masks['ufb.TermLength']" :required="true" :error="errors['ufb.TermLength']"  @change="errors['ufb.TermLength'] = null"  :disabled="loading.product || processing.plan_ufb" v-if="show_characteristic('plan_ufb', 'ufb.TermLength')"></app-input>
                                <app-input :readonly="readonlyMode" v-model="product_characteristics['ufb.LFCPlanName'].DefaultValue" label="LFC Plan Name" :required="true" :error="errors['ufb.LFCPlanName']" @change="errors['ufb.LFCPlanName'] = null" :disabled="loading.product || processing.plan_ufb" v-if="show_characteristic('plan_ufb', 'ufb.LFCPlanName')"></app-input>
                            </div>
                            <div class="input-line-dbl">
                                <app-input :readonly="readonlyMode" v-model="product_characteristics['ufb.Chorus.ProductID'].DefaultValue"  label="Chorus Product ID"   :required="true" :error="errors['ufb.Chorus.ProductID']"  @change="errors['ufb.Chorus.ProductID'] = null"  :disabled="loading.product || processing.plan_ufb" v-if="show_characteristic('plan_ufb', 'ufb.Chorus.ProductID')"></app-input>
                                <app-input :readonly="readonlyMode" v-model="product_characteristics['ufb.Chorus.Profile'].DefaultValue" label="Chorus Profile" :required="true" :error="errors['ufb.Chorus.Profile']" @change="errors['ufb.Chorus.Profile'] = null" :disabled="loading.product || processing.plan_ufb" v-if="show_characteristic('plan_ufb', 'ufb.Chorus.Profile')"></app-input>
                            </div>

                            <div class="input-line-dbl">
                                <app-dropdown-select :readonly="readonlyMode" v-model="product_characteristics['ufb.PrimaryOnlyOffer'].DefaultValue"    label="Is Primary Only Offer"       :error="errors['ufb.PrimaryOnlyOffer']" @select="errors['ufb.PrimaryOnlyOffer'] = null" :disabled="loading.product || processing.plan_ufb" v-if="show_characteristic('plan_ufb','ufb.PrimaryOnlyOffer')"
                                    :options="dictonaries.true_false"
                                ></app-dropdown-select>
                                <app-dropdown-select :readonly="readonlyMode" v-model="product_characteristics['ufb.PlanMarketSegment'].DefaultValue"    label="Plan Market Segment"       :error="errors['ufb.PlanMarketSegment']" @select="errors['ufb.PlanMarketSegment'] = null" :disabled="loading.product || processing.plan_ufb" v-if="show_characteristic('plan_ufb','ufb.PlanMarketSegment')"
                                    :options="dictonaries.biz_res"
                                ></app-dropdown-select>
                            </div>

                            <div class="input-line-dbl">
                                <app-dropdown-select :readonly="readonlyMode" v-model="product_characteristics['ufb.ActiveUNI_TAGGING'].DefaultValue"    label="UNI Tag Mode"       :error="errors['ufb.ActiveUNI_TAGGING']" @select="errors['ufb.ActiveUNI_TAGGING'] = null" :disabled="loading.product || processing.plan_ufb" v-if="show_characteristic('plan_ufb','ufb.ActiveUNI_TAGGING')"
                                    :options="dictonaries.uni_tagging"
                                ></app-dropdown-select>
                                <app-dropdown-select :readonly="readonlyMode" v-model="product_characteristics['ufb.ActiveCIRCUIT_ID_INSERTION'].DefaultValue"    label="Circuit ID Insertion"       :error="errors['ufb.ActiveCIRCUIT_ID_INSERTION']" @select="errors['ufb.ActiveCIRCUIT_ID_INSERTION'] = null" :disabled="loading.product || processing.plan_ufb" v-if="show_characteristic('plan_ufb','ufb.ActiveCIRCUIT_ID_INSERTION')"
                                    :options="dictonaries.circuit_id_insertion"
                                ></app-dropdown-select>
                            </div>

                            <div class="btns">
                                <button class="btn btn-small btn-primary" @click="save('plan_ufb')" :disabled="loading.product || processing.plan_ufb || !is_changed.plan_ufb" v-if="is_editing && !readonlyMode">Save</button>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 actions">
                        <button v-if="!readonlyMode" class="btn btn-middle btn-primary" @click="save('product')" :disabled="loading.product || processing.product || !is_changed.product">Save</button>
                        <button v-if="!readonlyMode && charsNeedFixing" class="btn btn-primary" @click="fixchars()" :disabled="loading.product || processing.product">Fix Characteristics</button>
                    </div>
                </div>

                <div class="row" v-if="showServiceProviderPricesTable && is_editing">
                    <div class="col-12">
                        <app-table
                            class="prices-table"

                            :cols="prices.cols"
                            :rows="prices_list"

                            title="Service Provider Pricing Allocation"

                            :loading="loading.product || loading.prices"

                            :selected-row="prices.selected"
                            @details="showPriceDetails"
                            @row-click-mobile="openPriceDetails"

                            @remove="removePrice"
                        >
                            <template #actions>
                                <button class="btn btn-middle btn-primary" @click="addPrice">Add</button>
                            </template>
                        </app-table>
                    </div>
                </div>
                <div class="row gap">
                    <div class="col-12">&nbsp;</div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <app-table
                            class="characteristics-table"

                            :class="readonlyMode ? 'readonly-mode' : 'edit-mode'"

                            :cols="characteristics.cols"
                            :rows="characteristics_list"
                            :collapsible="true"

                            title="Technical Product Characteristics"

                            :loading="loading.product || loading.characteristics"

                            :selected-row="characteristics.selected"
                            @details="showCharacteristicDetails"
                            @row-click-mobile="openCharacteristicDetails"

                            @remove="removeCharacteristic"
                        >
                            <template v-if="!readonlyMode" #actions>
                                <button class="btn btn-middle btn-primary" @click="addCharacteristics">Add</button>
                            </template>
                        </app-table>
                    </div>
                </div>

                <div class="row actions" v-if="!(is_new || readonlyMode)">
                    <div class="col-12 actions">
                        <button class="btn btn-middle btn-primary" @click="removeProduct" :disabled="loading.product || processing.product">Delete</button>
                    </div>
                </div>
            </div>
        </div>



        <dialog-product-characteristic-remove
            v-if="dialogs.characteristic.remove.uuid"
            v-model="dialogs.characteristic.remove.show"

            :uuid="dialogs.characteristic.remove.uuid"

            @confirm="onCharacteristicRemoved"
        ></dialog-product-characteristic-remove>

        <dialog-product-price-remove
            v-if="dialogs.price.remove.uuid"
            v-model="dialogs.price.remove.show"

            :uuid="dialogs.price.remove.uuid"

            @confirm="onPriceRemoved"
        ></dialog-product-price-remove>

        <dialog-product-remove
            v-if="dialogs.remove.uuid"
            v-model="dialogs.remove.show"

            :uuid="dialogs.remove.uuid"

            @removed="onProductRemoved"
        ></dialog-product-remove>
    </base-layout>
</template>

<script>
import { mapGetters } from 'vuex'

import baseLayout from '@/views/layouts/base-layout'

import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'

import appInput from '@/components/app-input'
import appDropdownSelect from '@/components/app-dropdown-select'

import appSidebarDetails from '@/components/app-sidebar-details'
import characteristicDetails from '@/views/product/characteristic'
import priceDetails from '@/views/product/price'

import appTable from '@/components/app-table'

import dialogProductCharacteristicRemove from '@/views/product/dialogs/dialog-product-characteristic-remove'
import dialogProductPriceRemove from '@/views/product/dialogs/dialog-product-price-remove'
import dialogProductRemove from '@/views/product/dialogs/dialog-product-remove'

import ProductsValidator from '@/validators/products-validator'
import errMessage from '@/helpers/errMessage'
import formatDateNano from '@/helpers/format-date-nano'

const VUE_APP_FWASPEED_DEFAULT_APN = process.env.VUE_APP_FWASPEED_DEFAULT_APN ? process.env.VUE_APP_FWASPEED_DEFAULT_APN : ''
const VUE_APP_FWASPEED_CELLULAR_SERVICE_UUID = process.env.VUE_APP_FWASPEED_CELLULAR_SERVICE_UUID ? process.env.VUE_APP_FWASPEED_CELLULAR_SERVICE_UUID : ''
const VUE_APP_FWASPEED_OCS_SERVICE_UUID = process.env.VUE_APP_FWASPEED_OCS_SERVICE_UUID ? process.env.VUE_APP_FWASPEED_OCS_SERVICE_UUID : ''

const VUE_APP_FWAVOLUME_DEFAULT_APN = process.env.VUE_APP_FWAVOLUME_DEFAULT_APN ? process.env.VUE_APP_FWAVOLUME_DEFAULT_APN : ''
const VUE_APP_FWAVOLUME_CELLULAR_SERVICE_UUID = process.env.VUE_APP_FWAVOLUME_CELLULAR_SERVICE_UUID ? process.env.VUE_APP_FWAVOLUME_CELLULAR_SERVICE_UUID : ''
const VUE_APP_FWAVOLUME_OCS_SERVICE_UUID = process.env.VUE_APP_FWAVOLUME_OCS_SERVICE_UUID ? process.env.VUE_APP_FWAVOLUME_OCS_SERVICE_UUID : ''

const VUE_APP_POSTPAY_DEFAULT_APN = process.env.VUE_APP_POSTPAY_DEFAULT_APN ? process.env.VUE_APP_POSTPAY_DEFAULT_APN : ''
const VUE_APP_POSTPAY_CELLULAR_SERVICE_UUID = process.env.VUE_APP_POSTPAY_CELLULAR_SERVICE_UUID ? process.env.VUE_APP_POSTPAY_CELLULAR_SERVICE_UUID : ''
const VUE_APP_POSTPAY_OCS_SERVICE_UUID = process.env.VUE_APP_POSTPAY_OCS_SERVICE_UUID ? process.env.VUE_APP_POSTPAY_OCS_SERVICE_UUID : ''
const VUE_APP_POSTPAY_MSG_SERVICE_UUID = process.env.VUE_APP_POSTPAY_MSG_SERVICE_UUID ? process.env.VUE_APP_POSTPAY_MSG_SERVICE_UUID : ''
const VUE_APP_POSTPAY_VOICEMAIL_SERVICE_UUID = process.env.VUE_APP_POSTPAY_VOICEMAIL_SERVICE_UUID ? process.env.VUE_APP_POSTPAY_VOICEMAIL_SERVICE_UUID : ''

const VUE_APP_PREPAY_DEFAULT_APN = process.env.VUE_APP_PREPAY_DEFAULT_APN ? process.env.VUE_APP_PREPAY_DEFAULT_APN : ''
const VUE_APP_PREPAY_CELLULAR_SERVICE_UUID = process.env.VUE_APP_PREPAY_CELLULAR_SERVICE_UUID ? process.env.VUE_APP_PREPAY_CELLULAR_SERVICE_UUID : ''
const VUE_APP_PREPAY_OCS_SERVICE_UUID = process.env.VUE_APP_PREPAY_OCS_SERVICE_UUID ? process.env.VUE_APP_PREPAY_OCS_SERVICE_UUID : ''
const VUE_APP_PREPAY_MSG_SERVICE_UUID = process.env.VUE_APP_PREPAY_MSG_SERVICE_UUID ? process.env.VUE_APP_PREPAY_MSG_SERVICE_UUID : ''
const VUE_APP_PREPAY_VOICEMAIL_SERVICE_UUID = process.env.VUE_APP_PREPAY_VOICEMAIL_SERVICE_UUID ? process.env.VUE_APP_PREPAY_VOICEMAIL_SERVICE_UUID : ''

const VUE_APP_UFB_SERVICE_UUID = process.env.VUE_APP_UFB_SERVICE_UUID ? process.env.VUE_APP_UFB_SERVICE_UUID : ''

const CENTS_CHARACTERISTICS = {
    'ocs.PurchaseBaseCost': true,
    'ocs.PurchaseDataPlanCost': true,
    'ocs.RecurringBaseCost': true,
    'ocs.RecurringDataPlanCost': true,

    'ocs.PurchaseCreditAmount': true,
    'ocs.RecurringCreditAmount': true,
}

const CHARACTERISTIC_GROUPS = {
    product: {
        'ocs.PurchaseBaseCost': null,
        'ocs.RecurringBaseCost': null,
        // 'ocs.PurchaseDataPlanCost': null,
        // 'ocs.RecurringDataPlanCost': null,
        'AllowCustomerPurchase': null,
        'CustomerVisible': null,
        'ocs.OfferPeriodCount': null,
        'ocs.OfferPeriodInterval': null,
        'ocs.ESPReseller': null,
    },
    data: {
        'ocs.PurchaseDataAllowance': null,
        'ocs.RecurringDataAllowance': null,
        'ocs.MaxUnusedDataRollOver': null,
        'ocs.DataUsageRule': null,
        'ocs.PrepaidDataRollOverAllowed': null,
        'cellular.SpeedDown': null,
        'cellular.SpeedUp': null,
        'ocs.SpeedLimitDL': null,
        'ocs.SpeedLimitUL': null,
        'cellular.APNList': null,
    },
    voice: {
        'ocs.PurchaseVoiceAllowance': null,
        'ocs.RecurringVoiceAllowance': null,
        'ocs.MaxUnusedVoiceRollOver': null,
        'ocs.VoiceUsageRule': null,
        'ocs.PrepaidVoiceRollOverAllowed': null,
        'ocs.PrepaidCUGUsageRule': null,
        'cellular.IncomingCallerID': null,
        'cellular.OutgoingCallerID': null,
        'cellular.AllowCallHold': null,
    },
    sms: {
        'ocs.PurchaseSMSAllowance': null,
        'ocs.RecurringSMSAllowance': null,
        // 'ocs.MaxUnusedSMSRollOver': null,
        'ocs.SMSUsageRule': null,
        'ocs.PrepaidSMSRollOverAllowed': null,
    },
    mms: {
        'ocs.PurchaseMMSAllowance': null,
        'ocs.RecurringMMSAllowance': null,
        // 'ocs.MaxUnusedMMSRollOver': null,
        'ocs.MMSUsageRule': null,
        'ocs.PrepaidMMSRollOverAllowed': null,
    },
    prepay_credit: {
        'ocs.PurchaseCreditAmount': null,
        'ocs.RecurringCreditAmount': null,
        'ocs.PrepaidCreditExpiryDays': null,
        'ocs.PrepaidRollOverExpiryDays': null,

        // 'ocs.RecurringBaseCost': null,
        // 'ocs.PurchaseDataPlanCost': null,
        // 'ocs.RecurringRetailPrice': null,
        // 'ocs.RecurringCreditAmount': null,
        // 'ocs.RecurringDataAllowance': null,
        // 'ocs.RecurringVoiceAllowance': null,
        // 'ocs.RecurringSMSAllowance': null,
        // 'ocs.RecurringMMSAllowance': null,
    },
    prepay_purchase: {
        'RequiresAnyBasePlan': null,
        'RequiresNoBasePlan': null,
        'AllowAutoRenew': null,
        'CustomerVisible': null,
    },
    plan_ufb: {
        'ufb.TermLength': null,
        'ufb.LFCPlanName': null,
        'ufb.Chorus.ProductID': null,
        'ufb.Chorus.Profile': null,
        'ufb.PrimaryOnlyOffer': null,
        'ufb.PlanMarketSegment': null,
        'ufb.ActiveCIRCUIT_ID_INSERTION': null,
        'ufb.ActiveUNI_TAGGING': null,
    },
    throttling_speed_limits: {
        'ocs.SpeedLimitDL': null,
        'ocs.SpeedLimitUL': null,
        'ocs.Throttle':     null,
        'ocs.Tethering':    null,
    },
    mobile_esp: {
        'ocs.OutgoingIDDBarred': null,
        'ocs.OutgoingTextBarred': null,
        'ocs.BlockPremiumCalls': null,
        'ocs.PremiumCallsBarred': null,
        'ocs.BlockPremiumSMS': null,
        'ocs.PremiumSMSBarred': null,
        'ocs.RoamingBarred': null,
        'ocs.OutCallsWhileRoamingBarred': null,
        'ocs.InCallsWhileRoamingBarred': null,
        'ocs.OOBWhileRoamingBarred': null,
        'ocs.ChargeableDataRGBarred': null,
    },
}

const PER_PRODUCT_UI_ELEMENTS = {
    UNKNOWN: {
        product: {},
        data: {},
        voice: {},
        sms: {},
        mms: {},
        prepay_credit: {},
        prepay_purchase: {},
        plan_ufb: {},
        throttling_speed_limits: {},
        mobile_esp: {},
    },
    FWA_Volume: {
        product: {
            ProductVariant: true,
            Description: true,
            'ocs.RecurringBaseCost': true,
        },
        data: {
            'ocs.PurchaseDataAllowance': true,
            'ocs.RecurringDataAllowance': true,
            'ocs.DataUsageRule': true,
            'ocs.SpeedLimitDL': true,
            'ocs.SpeedLimitUL': true,
            'cellular.APNList': true,
            'FWA.ServiceAddress': true,
        },
        sms: {
            'ocs.PurchaseSMSAllowance': true,
            'ocs.RecurringSMSAllowance': true,
            'ocs.SMSUsageRule': true,
        },
        throttling_speed_limits: {
            'ocs.SpeedLimitDL': true,
            'ocs.SpeedLimitUL': true,
            'ocs.Throttle':     true,
            'ocs.Tethering':    true,
        },
    },
    FWA_Speed: {
        product: {
            ProductVariant: true,
            Description: true,
            'ocs.RecurringBaseCost': true,
        },
        data: {
            'ocs.RecurringDataAllowance': true,
            'ocs.SpeedLimitDL': true,
            'ocs.SpeedLimitUL': true,
            'cellular.APNList': true,
            'FWA.ServiceAddress': true,
        },
        sms: {
            'ocs.PurchaseSMSAllowance': true,
            'ocs.RecurringSMSAllowance': true,
            'ocs.SMSUsageRule': true,
        },
        throttling_speed_limits: {
            'ocs.SpeedLimitDL': true,
            'ocs.SpeedLimitUL': true,
            'ocs.Throttle':     true,
            'ocs.Tethering':    true,
        },
    },
    postpaymobile: {
        product: {
            ProductVariant: true,
            Description: true,
            'ocs.RecurringDataPlanCost': true,
            'ocs.RecurringBaseCost': true,
            'ocs.ESPReseller': true,
        },
        data: {
            'ocs.PurchaseDataAllowance': true,
            'ocs.RecurringDataAllowance': true,
            'ocs.DataUsageRule': true,
            'ocs.SpeedLimitDL': true,
            'ocs.SpeedLimitUL': true,
            'cellular.APNList': true,
        },
        voice: {
            'ocs.PurchaseVoiceAllowance': true,
            'ocs.RecurringVoiceAllowance': true,
            'ocs.VoiceUsageRule': true,
            'cellular.IncomingCallerID': true,
            'cellular.OutgoingCallerID': true,
            'cellular.AllowCallHold': true,
        },
        sms: {
            'ocs.PurchaseSMSAllowance': true,
            'ocs.RecurringSMSAllowance': true,
            'ocs.SMSUsageRule': true,
        },
        mms: {
            'ocs.PurchaseMMSAllowance': true,
            'ocs.RecurringMMSAllowance': true,
            'ocs.MMSUsageRule': true,
        },
        throttling_speed_limits: {
            'ocs.SpeedLimitDL': true,
            'ocs.SpeedLimitUL': true,
            'ocs.Throttle':     true,
            'ocs.Tethering':    true,
        },
        mobile_esp: {
            'ocs.OutgoingIDDBarred': true,
            'ocs.OutgoingTextBarred': true,
            'ocs.BlockPremiumCalls': null,
            'ocs.PremiumCallsBarred': true,
            'ocs.BlockPremiumSMS': null,
            'ocs.PremiumSMSBarred': true,
            'ocs.RoamingBarred': true,
            'ocs.OutCallsWhileRoamingBarred': true,
            'ocs.InCallsWhileRoamingBarred': true,
            'ocs.OOBWhileRoamingBarred': true,
            'ocs.ChargeableDataRGBarred': true,
        },
    },
    prepaymobile: {
        product: {
            ProductVariant: true,
            Description: true,
            'ocs.OfferPeriodInterval': true,
            'ocs.OfferPeriodCount': true,
            // 'ocs.RecurringDataPlanCost': true,
            // 'ocs.RecurringBaseCost': true,
            // 'CustomerVisible': true,
            // 'AllowCustomerPurchase': true,
        },
        data: {
            // 'ocs.PurchaseDataAllowance': true,
            // 'ocs.DataUsageRule': true,
            // // 'ocs.MaxUnusedDataRollOver': true,
            // 'ocs.PrepaidDataRollOverAllowed': true,
            'ocs.SpeedLimitDL': true,
            'ocs.SpeedLimitUL': true,
            'cellular.APNList': true,
        },
        voice: {
            // 'ocs.PurchaseVoiceAllowance': true,
            // 'ocs.VoiceUsageRule': true,
            // // 'ocs.MaxUnusedVoiceRollOver': true,
            // 'ocs.PrepaidVoiceRollOverAllowed': true,
            // 'ocs.PrepaidCUGUsageRule': true,
        },
        sms: {
            // 'ocs.PurchaseSMSAllowance': true,
            // 'ocs.SMSUsageRule': true,
            // 'ocs.PrepaidSMSRollOverAllowed': true,
        },
        mms: {
            // 'ocs.PurchaseMMSAllowance': true,
            // 'ocs.MMSUsageRule': true,
            // 'ocs.PrepaidMMSRollOverAllowed': true,
        },
        prepay_credit: {
            // 'ocs.PrepaidCreditExpiryDays': true,
            // 'ocs.PrepaidRollOverExpiryDays': true,
            // 'ocs.PurchaseCreditAmount': true,
            // 'ocs.RecurringCreditAmount': true,
        },
        prepay_purchase: {
            // 'RequiresAnyBasePlan': true,
            // 'RequiresNoBasePlan': true,
            // 'AllowAutoRenew': true,
        },
        throttling_speed_limits: {
            'ocs.SpeedLimitDL': true,
            'ocs.SpeedLimitUL': true,
            'ocs.Throttle':     true,
            'ocs.Tethering':    true,
        },
    },
    prepaymobileAddon: {
        product: {
            ProductVariant: true,
            Description: true,
            'ocs.OfferPeriodInterval': true,
            'ocs.RecurringDataPlanCost': true,
            'ocs.RecurringBaseCost': true,
            'ocs.OfferPeriodInterval': true,
            'ocs.OfferPeriodCount': true,
            'CustomerVisible': true,
            'AllowCustomerPurchase': true,
        },
        data: {
            'ocs.PurchaseDataAllowance': true,
            'ocs.DataUsageRule': true,
            // // 'ocs.MaxUnusedDataRollOver': true,
            'ocs.PrepaidDataRollOverAllowed': true,
            'cellular.APNList': true,
        },
        voice: {
            'ocs.PurchaseVoiceAllowance': true,
            'ocs.VoiceUsageRule': true,
            // // 'ocs.MaxUnusedVoiceRollOver': true,
            'ocs.PrepaidVoiceRollOverAllowed': true,
            'ocs.PrepaidCUGUsageRule': true,
        },
        sms: {
            'ocs.PurchaseSMSAllowance': true,
            'ocs.SMSUsageRule': true,
            'ocs.PrepaidSMSRollOverAllowed': true,
        },
        mms: {
            'ocs.PurchaseMMSAllowance': true,
            'ocs.MMSUsageRule': true,
            'ocs.PrepaidMMSRollOverAllowed': true,
        },
        prepay_credit: {
            'ocs.PrepaidCreditExpiryDays': true,
            'ocs.PrepaidRollOverExpiryDays': true,
            'ocs.PurchaseCreditAmount': true,
            'ocs.RecurringCreditAmount': true,
        },
        prepay_purchase: {
            'IsBasePlan': true,
            'RequiresAnyBasePlan': true,
            'RequiresNoBasePlan': true,
            'AllowAutoRenew': true,
        },
        throttling_speed_limits: {
            'ocs.SpeedLimitDL': true,
            'ocs.SpeedLimitUL': true,
            'ocs.Throttle':     true,
            'ocs.Tethering':    true,
        },
    },
    UFB: {
        product: {
            ProductVariant: true,
            Description: true,
        },
        plan_ufb: {
            'ufb.TermLength':  true,
            'ufb.LFCPlanName': true,
            'ufb.Chorus.ProductID': true,
            'ufb.Chorus.Profile': true,
            'ufb.PrimaryOnlyOffer': true,
            'ufb.PlanMarketSegment': true,
            'ufb.ActiveCIRCUIT_ID_INSERTION': true,
            'ufb.ActiveUNI_TAGGING': true,
        },
    },
}

const PER_PRODUCT_CHARACTERISTICS = {
    FWA_Speed: [
        { Name: "sim.IMSI",                    DefaultValue: "",                                     Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "sim.SIMID",                   DefaultValue: "",                                     Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "cellular.MSISDN",             DefaultValue: "",                                     Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "cellular.APNList",            DefaultValue: VUE_APP_FWASPEED_DEFAULT_APN,           Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "cellular.SpeedUp",            DefaultValue: "64000000",                             Required: false, ValueType: "number", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "cellular.SpeedDown",          DefaultValue: "256000000",                            Required: false, ValueType: "number", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "FWA.PrequalUUID",             DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "FWA.ServiceAddress",          DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "cellular.DataEnabled5G",      DefaultValue: "true",                                 Required: false, ValueType: "bool",   ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "cellular.DataEnabled4G",      DefaultValue: "true",                                 Required: false, ValueType: "bool",   ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "cellular.DataEnabled2G",      DefaultValue: "true",                                 Required: false, ValueType: "bool",   ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "cellular.DataEnabled3G",      DefaultValue: "true",                                 Required: false, ValueType: "bool",   ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "cellular.ServiceCatalogUUID", DefaultValue: VUE_APP_FWASPEED_CELLULAR_SERVICE_UUID, Required: false, ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "ocs.PlanCost",                DefaultValue: "",                                     Required: false, ValueType: "price",  ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.PurchaseBaseCost",        DefaultValue: "0",                                    Required: false, ValueType: "price",  ValidationRules:"", CanAdd:true,  CanUpdate: true },
        { Name: "ocs.RecurringBaseCost",       DefaultValue: "0",                                    Required: true,  ValueType: "number", ValidationRules:"", CanAdd:true,  CanUpdate: true },
        { Name: "ocs.PurchaseDataPlanCost",    DefaultValue: "0",                                    Required: false, ValueType: "price",  ValidationRules:"", CanAdd:true,  CanUpdate: true },
        { Name: "ocs.RecurringDataPlanCost",   DefaultValue: "0",                                    Required: true,  ValueType: "number", ValidationRules:"", CanAdd:true,  CanUpdate: true },
        { Name: "ocs.PlanType",                DefaultValue: "FWA_Speed",                            Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.ServiceType",             DefaultValue: "FWA_Speed",                            Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.PlanName",                DefaultValue: "vfflex_service_fwa_plan",              Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "ocs.PurchaseDataAllowance",   DefaultValue: "0",                                    Required: true,  ValueType: "number", ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "ocs.RecurringDataAllowance",  DefaultValue: "0",                                    Required: true,  ValueType: "number", ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "ocs.DataUsageRule",           DefaultValue: "Unlimited",                            Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "ocs.SMSUsageRule",            DefaultValue: "Limited",                              Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "ocs.PurchaseSMSAllowance",    DefaultValue: "100",                                  Required: true,  ValueType: "number", ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "ocs.RecurringSMSAllowance",   DefaultValue: "100",                                  Required: true,  ValueType: "number", ValidationRules:"", CanAdd:true,  CanUpdate: false },
        { Name: "ocs.ProductVariant",          DefaultValue: "",                                     Required: true,  ValueType: "number", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.ServiceCatalogUUID",      DefaultValue: VUE_APP_FWASPEED_OCS_SERVICE_UUID,      Required: false, ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false },

        { Name: "ProductName",                 DefaultValue: "",                                     Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ProductDescription",          DefaultValue: "",                                     Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },

        { Name: "ocs.SpeedLimitDL", DefaultValue: "", Required: false, ValueType: "string", ValidationRules: "", CanAdd: true, CanUpdate: true },
        { Name: "ocs.SpeedLimitUL", DefaultValue: "", Required: false, ValueType: "string", ValidationRules: "", CanAdd: true, CanUpdate: true },
        { Name: "ocs.Throttle",     DefaultValue: "", Required: false, ValueType: "string", ValidationRules: "", CanAdd: true, CanUpdate: true },
        { Name: "ocs.Tethering",    DefaultValue: "", Required: false, ValueType: "bool", ValidationRules: "", CanAdd: true, CanUpdate: true },
    ],

    FWA_Volume: [
        { Name: "sim.IMSI",                    DefaultValue: "",                                     Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "sim.SIMID",                   DefaultValue: "",                                     Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "cellular.MSISDN",             DefaultValue: "",                                     Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "cellular.APNList",            DefaultValue: VUE_APP_FWAVOLUME_DEFAULT_APN,          Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "FWA.PrequalUUID",             DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "FWA.ServiceAddress",          DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "cellular.DataEnabled5G",      DefaultValue: "true",                                 Required: false, ValueType: "bool",   ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "cellular.DataEnabled4G",      DefaultValue: "true",                                 Required: false, ValueType: "bool",   ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "cellular.DataEnabled2G",      DefaultValue: "true",                                 Required: false, ValueType: "bool",   ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "cellular.DataEnabled3G",      DefaultValue: "true",                                 Required: false, ValueType: "bool",   ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "cellular.SpeedUp",            DefaultValue: "64000000",                             Required: false, ValueType: "number", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "cellular.SpeedDown",          DefaultValue: "256000000",                            Required: false, ValueType: "number", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "cellular.ServiceCatalogUUID", DefaultValue: VUE_APP_FWAVOLUME_CELLULAR_SERVICE_UUID,Required: false, ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "ocs.PlanCost",                DefaultValue: "",                                     Required: false, ValueType: "price",  ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.PurchaseBaseCost",        DefaultValue: "0",                                    Required: false, ValueType: "price",  ValidationRules:"", CanAdd:true,  CanUpdate: true },
        { Name: "ocs.RecurringBaseCost",       DefaultValue: "0",                                    Required: true,  ValueType: "price",  ValidationRules:"", CanAdd:true,  CanUpdate: true },
        { Name: "ocs.PurchaseDataPlanCost",    DefaultValue: "0",                                    Required: false, ValueType: "price",  ValidationRules:"", CanAdd:true,  CanUpdate: true },
        { Name: "ocs.RecurringDataPlanCost",   DefaultValue: "0",                                    Required: true,  ValueType: "price",  ValidationRules:"", CanAdd:true,  CanUpdate: true },
        { Name: "ocs.PlanType",                DefaultValue: "FWA_Volume",                           Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.ServiceType",             DefaultValue: "FWA_Volume",                           Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.PlanName",                DefaultValue: "vfflex_service_fwa_plan",              Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "ocs.PurchaseDataAllowance",   DefaultValue: "0",                                    Required: true,  ValueType: "number", ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "ocs.RecurringDataAllowance",  DefaultValue: "0",                                    Required: true,  ValueType: "number", ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "ocs.DataUsageRule",           DefaultValue: "Limited",                              Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "ocs.SMSUsageRule",            DefaultValue: "Limited",                              Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "ocs.PurchaseSMSAllowance",    DefaultValue: "100",                                  Required: true,  ValueType: "number", ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "ocs.RecurringSMSAllowance",   DefaultValue: "100",                                  Required: true,  ValueType: "number", ValidationRules:"", CanAdd:true,  CanUpdate: false },
        { Name: "ocs.ProductVariant",          DefaultValue: "",                                     Required: true,  ValueType: "number", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.ServiceCatalogUUID",      DefaultValue: VUE_APP_FWAVOLUME_OCS_SERVICE_UUID, Required: false, ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false },

        { Name: "ProductName",                 DefaultValue: "",                                     Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ProductDescription",          DefaultValue: "",                                     Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },

        { Name: "ocs.SpeedLimitDL", DefaultValue: "", Required: false, ValueType: "string", ValidationRules: "", CanAdd: true, CanUpdate: true },
        { Name: "ocs.SpeedLimitUL", DefaultValue: "", Required: false, ValueType: "string", ValidationRules: "", CanAdd: true, CanUpdate: true },
        { Name: "ocs.Throttle",     DefaultValue: "", Required: false, ValueType: "string", ValidationRules: "", CanAdd: true, CanUpdate: true },
        { Name: "ocs.Tethering",    DefaultValue: "", Required: false, ValueType: "bool", ValidationRules: "", CanAdd: true, CanUpdate: true },
    ],

    postpaymobile: [
        { Name: "cellular.IMSI",               DefaultValue: "",                                     Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "cellular.MSISDN",             DefaultValue: "",                                     Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "cellular.APNList",            DefaultValue: VUE_APP_POSTPAY_DEFAULT_APN,            Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "cellular.DataEnabled2G",      DefaultValue: "true",                                 Required: false, ValueType: "bool",   ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "cellular.DataEnabled3G",      DefaultValue: "true",                                 Required: false, ValueType: "bool",   ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "cellular.DataEnabled4G",      DefaultValue: "true",                                 Required: false, ValueType: "bool",   ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "cellular.DataEnabled5G",      DefaultValue: "true",                                 Required: false, ValueType: "bool",   ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "cellular.ServiceCatalogUUID", DefaultValue: VUE_APP_POSTPAY_CELLULAR_SERVICE_UUID,  Required: false, ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "cellular.SpeedUp",            DefaultValue: "64000000",                             Required: false, ValueType: "number", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "cellular.SpeedDown",          DefaultValue: "256000000",                            Required: false, ValueType: "number", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "cellular.AllowConferencing",  DefaultValue: "true",                                 Required: false, ValueType: "bool",   ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "cellular.OutgoingCallerID",   DefaultValue: "3",                                    Required: false, ValueType: "number", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "cellular.IncomingCallerID",   DefaultValue: "true",                                 Required: false, ValueType: "bool",   ValidationRules:"", CanAdd:false, CanUpdate: true  },

        { Name: "cellular.AllowCallHold",         DefaultValue: "true", Required: false, ValueType: "bool", ValidationRules: "", CanAdd: true, CanUpdate: true },
        { Name: "cellular.CallWaiting.Available", DefaultValue: "true", Required: false, ValueType: "bool", ValidationRules: "", CanAdd: true, CanUpdate: true },
        { Name: "cellular.CallWaiting.Enabled",   DefaultValue: "true", Required: false, ValueType: "bool", ValidationRules: "", CanAdd: true, CanUpdate: true },

        { Name: "cellular.BarIncomingCalls", DefaultValue: "false", Required: false, ValueType: "bool", ValidationRules: "", CanAdd: true, CanUpdate: true },
        { Name: "cellular.BarOutgoingCalls", DefaultValue: "false", Required: false, ValueType: "bool", ValidationRules: "", CanAdd: true, CanUpdate: true },

        { Name: "cellular.AllowIncomingCalls",        DefaultValue: "true", Required: false, ValueType: "bool", ValidationRules: "", CanAdd: true, CanUpdate: true },
        { Name: "cellular.AllowIncomingWhileRoaming", DefaultValue: "true", Required: false, ValueType: "bool", ValidationRules: "", CanAdd: true, CanUpdate: true },

        { Name: "cellular.AllowOutgoingCalls",              DefaultValue: "true", Required: false, ValueType: "bool", ValidationRules: "", CanAdd: true, CanUpdate: true },
        { Name: "cellular.AllowOutgoingInternationalCalls", DefaultValue: "false", Required: false, ValueType: "bool", ValidationRules: "", CanAdd: true, CanUpdate: true },

        { Name: "cellular.AllowSMSSend",    DefaultValue: "true", Required: false, ValueType: "bool", ValidationRules: "", CanAdd: true, CanUpdate: true },
        { Name: "cellular.AllowSMSRecieve", DefaultValue: "true", Required: false, ValueType: "bool", ValidationRules: "", CanAdd: true, CanUpdate: true },

        { Name: "cellular.CFB.Active",        DefaultValue: "false",  Required: false, ValueType: "bool",   ValidationRules: "", CanAdd: true, CanUpdate: true },
        { Name: "cellular.CFB.Available",     DefaultValue: "true", Required: false, ValueType: "bool",   ValidationRules: "", CanAdd: true, CanUpdate: true },
        { Name: "cellular.CFB.Destination",   DefaultValue: "",    Required: false, ValueType: "string", ValidationRules: "", CanAdd: true, CanUpdate: true },
        { Name: "cellular.CFD",               DefaultValue: "",    Required: false, ValueType: "string", ValidationRules: "", CanAdd: true, CanUpdate: true },
        { Name: "cellular.CFNRC.Active",      DefaultValue: "false",  Required: false, ValueType: "bool",   ValidationRules: "", CanAdd: true, CanUpdate: true },
        { Name: "cellular.CFNRC.Available",   DefaultValue: "true", Required: false, ValueType: "bool",   ValidationRules: "", CanAdd: true, CanUpdate: true },
        { Name: "cellular.CFNRC.Destination", DefaultValue: "",    Required: false, ValueType: "string", ValidationRules: "", CanAdd: true, CanUpdate: true },
        { Name: "cellular.CFNRY.Active",      DefaultValue: "false",  Required: false, ValueType: "bool",   ValidationRules: "", CanAdd: true, CanUpdate: true },
        { Name: "cellular.CFNRY.Available",   DefaultValue: "true", Required: false, ValueType: "bool",   ValidationRules: "", CanAdd: true, CanUpdate: true },
        { Name: "cellular.CFNRY.Destination", DefaultValue: "",    Required: false, ValueType: "string", ValidationRules: "", CanAdd: true, CanUpdate: true },
        { Name: "cellular.CFNRY.Ringtime",    DefaultValue: "30",  Required: false, ValueType: "number", ValidationRules: "", CanAdd: true, CanUpdate: true },
        { Name: "cellular.CFU.Active",        DefaultValue: "false",  Required: false, ValueType: "bool",   ValidationRules: "", CanAdd: true, CanUpdate: true },
        { Name: "cellular.CFU.Available",     DefaultValue: "true", Required: false, ValueType: "bool",   ValidationRules: "", CanAdd: true, CanUpdate: true },
        { Name: "cellular.CFU.Destination",   DefaultValue: "",    Required: false, ValueType: "string", ValidationRules: "", CanAdd: true, CanUpdate: true },

        { Name: "cellular.DataEnabled2G", DefaultValue: "true", Required: false, ValueType: "bool", ValidationRules: "", CanAdd: true, CanUpdate: true },
        { Name: "cellular.DataEnabled3G", DefaultValue: "true", Required: false, ValueType: "bool", ValidationRules: "", CanAdd: true, CanUpdate: true },
        { Name: "cellular.DataEnabled4G", DefaultValue: "true", Required: false, ValueType: "bool", ValidationRules: "", CanAdd: true, CanUpdate: true },
        { Name: "cellular.DataEnabled5G", DefaultValue: "true", Required: false, ValueType: "bool", ValidationRules: "", CanAdd: true, CanUpdate: true },

        { Name: "sim.UUID",                    DefaultValue: "",                                     Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "sim.IMSI",                    DefaultValue: "",                                     Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "sim.SIMID",                   DefaultValue: "",                                     Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },

        { Name: "messaging.MSISDN",             DefaultValue: "",   Required: false, ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true },
        { Name: "messaging.BlockListEnabled",   DefaultValue: "",   Required: false, ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true },
        { Name: "messaging.BillingID",          DefaultValue: "15", Required: false, ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "messaging.BlockedNumbers",     DefaultValue: "",   Required: false, ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true },
        { Name: "messaging.Status",             DefaultValue: "",   Required: false, ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true },
        { Name: "messaging.ServiceCatalogUUID", DefaultValue: VUE_APP_POSTPAY_MSG_SERVICE_UUID,  Required: false, ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false },

        { Name: "voicemail.MSISDN",               DefaultValue: "",  Required: false, ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true },
        { Name: "voicemail.OutcallNumberAddPart", DefaultValue: "",  Required: false, ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true },
        { Name: "voicemail.FaxMessageEnabled",    DefaultValue: "",  Required: false, ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true },
        { Name: "voicemail.FaxMSISDN",            DefaultValue: "",  Required: false, ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true },
        { Name: "voicemail.SecondaryVoiceMSISDN", DefaultValue: "",  Required: false, ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true },
        { Name: "voicemail.NotifyEmail",          DefaultValue: "",  Required: false, ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true },
        { Name: "voicemail.PIN",                  DefaultValue: "",  Required: false, ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true },
        { Name: "voicemail.ClassOfService",       DefaultValue: "43", Required: false, ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true },
        { Name: "voicemail.ServiceCatalogUUID",   DefaultValue: VUE_APP_POSTPAY_VOICEMAIL_SERVICE_UUID, Required: false, ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false },

        { Name: "ocs.ProductVariant",          DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.PlanType",                DefaultValue: "Postpaid",                             Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.ServiceType",             DefaultValue: "Postpaid",                             Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.PlanName",                DefaultValue: "vfflex_esp_service_pos_plan",          Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.ServiceCatalogUUID",      DefaultValue: VUE_APP_POSTPAY_OCS_SERVICE_UUID,       Required: false, ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "ocs.PurchaseBaseCost",        DefaultValue: "0",                                    Required: false, ValueType: "price",  ValidationRules:"", CanAdd:true,  CanUpdate: true },
        { Name: "ocs.RecurringBaseCost",       DefaultValue: "0",                                    Required: true,  ValueType: "price", ValidationRules:"",  CanAdd:true,  CanUpdate: true },
        { Name: "ocs.PurchaseDataPlanCost",    DefaultValue: "0",                                    Required: false, ValueType: "price",  ValidationRules:"", CanAdd:true,  CanUpdate: true },
        { Name: "ocs.RecurringDataPlanCost",   DefaultValue: "0",                                    Required: true,  ValueType: "price", ValidationRules:"",  CanAdd:true,  CanUpdate: true },
        { Name: "ocs.PurchaseDataAllowance",   DefaultValue: "1000",                                 Required: true,  ValueType: "number", ValidationRules:"", CanAdd:true,  CanUpdate: false },
        { Name: "ocs.RecurringDataAllowance",  DefaultValue: "1000",                                 Required: true,  ValueType: "number", ValidationRules:"", CanAdd:true,  CanUpdate: false },
        { Name: "ocs.PurchaseVoiceAllowance",  DefaultValue: "200",                                  Required: true,  ValueType: "number", ValidationRules:"", CanAdd:true,  CanUpdate: false },
        { Name: "ocs.RecurringVoiceAllowance", DefaultValue: "200",                                  Required: true,  ValueType: "number", ValidationRules:"", CanAdd:true,  CanUpdate: false },
        { Name: "ocs.PurchaseSMSAllowance",    DefaultValue: "500",                                  Required: true,  ValueType: "number", ValidationRules:"", CanAdd:true,  CanUpdate: false },
        { Name: "ocs.RecurringSMSAllowance",   DefaultValue: "500",                                  Required: true,  ValueType: "number", ValidationRules:"", CanAdd:true,  CanUpdate: false },
        { Name: "ocs.PurchaseMMSAllowance",    DefaultValue: "500",                                  Required: true,  ValueType: "number", ValidationRules:"", CanAdd:true,  CanUpdate: false },
        { Name: "ocs.RecurringMMSAllowance",   DefaultValue: "500",                                  Required: true,  ValueType: "number", ValidationRules:"", CanAdd:true,  CanUpdate: false },
        { Name: "ocs.MMSUsageRule",            DefaultValue: "Limited",                              Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.SMSUsageRule",            DefaultValue: "Limited",                              Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.VoiceUsageRule",          DefaultValue: "Limited",                              Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.DataUsageRule",           DefaultValue: "Limited",                              Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.PlanCost",                DefaultValue: "",                                     Required: false, ValueType: "price",  ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.PurchaseRetailPrice",     DefaultValue: "0",                                    Required: false, ValueType: "price",  ValidationRules:"", CanAdd:false,  CanUpdate: false  },
        { Name: "ocs.RecurringRetailPrice",    DefaultValue: "0",                                    Required: false, ValueType: "price",  ValidationRules:"", CanAdd:false,  CanUpdate: false  },

        { Name: "ProductName",                 DefaultValue: "",                                     Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ProductDescription",          DefaultValue: "",                                     Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },

        { Name: "ocs.SpeedLimitDL", DefaultValue: "", Required: false, ValueType: "string", ValidationRules: "", CanAdd: true, CanUpdate: true },
        { Name: "ocs.SpeedLimitUL", DefaultValue: "", Required: false, ValueType: "string", ValidationRules: "", CanAdd: true, CanUpdate: true },
        { Name: "ocs.Throttle",     DefaultValue: "", Required: false, ValueType: "string", ValidationRules: "", CanAdd: true, CanUpdate: true },
        { Name: "ocs.Tethering",    DefaultValue: "", Required: false, ValueType: "bool", ValidationRules: "", CanAdd: true, CanUpdate: true },

        { Name: "ocs.ESPReseller",        DefaultValue: "true",  Required: false, ValueType: "bool", ValidationRules:"", CanAdd: true, CanUpdate: true },
        { Name: "ocs.OutgoingIDDBarred",  DefaultValue: "false", Required: false, ValueType: "bool", ValidationRules:"", CanAdd: true, CanUpdate: true },
        { Name: "ocs.OutgoingTextBarred", DefaultValue: "false", Required: false, ValueType: "bool", ValidationRules:"", CanAdd: true, CanUpdate: true },

        { Name: "ocs.BlockPremiumCalls",  DefaultValue: "false", Required: false, ValueType: "bool", ValidationRules: "", CanAdd: true, CanUpdate: true },
        { Name: "ocs.PremiumCallsBarred", DefaultValue: "",      Required: false, ValueType: "string", ValidationRules:"", CanAdd: true, CanUpdate: true },

        { Name: "ocs.BlockPremiumSMS",    DefaultValue: "false", Required: false, ValueType: "bool", ValidationRules: "", CanAdd: true, CanUpdate: true },
        { Name: "ocs.PremiumSMSBarred",   DefaultValue: "",      Required: false, ValueType: "string", ValidationRules:"", CanAdd: true, CanUpdate: true },

        { Name: "ocs.RoamingBarred",              DefaultValue: "false", Required: false, ValueType: "bool", ValidationRules:"", CanAdd: true, CanUpdate: true },
        { Name: "ocs.OutCallsWhileRoamingBarred", DefaultValue: "false", Required: false, ValueType: "bool", ValidationRules:"", CanAdd: true, CanUpdate: true },
        { Name: "ocs.InCallsWhileRoamingBarred",  DefaultValue: "false", Required: false, ValueType: "bool", ValidationRules:"", CanAdd: true, CanUpdate: true },
        { Name: "ocs.OOBWhileRoamingBarred",      DefaultValue: "false", Required: false, ValueType: "bool", ValidationRules:"", CanAdd: true, CanUpdate: true },
        { Name: "ocs.ChargeableDataRGBarred",     DefaultValue: "false", Required: false, ValueType: "bool", ValidationRules:"", CanAdd: true, CanUpdate: true },
    ],

    prepaymobile: [
        { Name: "IsBasePlan",                  DefaultValue: "false",   Required: false, ValueType: "bool", ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "RequiresAnyBasePlan",         DefaultValue: "false",   Required: false, ValueType: "bool", ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "RequiresNoBasePlan",          DefaultValue: "false",   Required: false, ValueType: "bool", ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "AllowAutoRenew",              DefaultValue: "true",  Required: false, ValueType: "bool", ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "AllowCustomerPurchase",       DefaultValue: "true",  Required: false, ValueType: "bool", ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "CustomerVisible",             DefaultValue: "false",   Required: false, ValueType: "bool", ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "ocs.OfferPeriodInterval",     DefaultValue: "days", Required: false, ValueType: "string", ValidationRules:"", CanAdd:false,  CanUpdate: false  },
        { Name: "ocs.OfferPeriodCount",        DefaultValue: "28",   Required: false, ValueType: "number", ValidationRules:"", CanAdd:false,  CanUpdate: false  },

        { Name: "cellular.IMSI",               DefaultValue: "",                                     Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "cellular.MSISDN",             DefaultValue: "",                                     Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "cellular.APNList",            DefaultValue: VUE_APP_PREPAY_DEFAULT_APN,             Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "cellular.DataEnabled2G",      DefaultValue: "true",                                 Required: false, ValueType: "bool",   ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "cellular.DataEnabled3G",      DefaultValue: "true",                                 Required: false, ValueType: "bool",   ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "cellular.DataEnabled4G",      DefaultValue: "true",                                 Required: false, ValueType: "bool",   ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "cellular.DataEnabled5G",      DefaultValue: "true",                                 Required: false, ValueType: "bool",   ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "cellular.ServiceCatalogUUID", DefaultValue: VUE_APP_PREPAY_CELLULAR_SERVICE_UUID,   Required: false, ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "cellular.SpeedUp",            DefaultValue: "350000000",                            Required: false, ValueType: "number", ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "cellular.SpeedDown",          DefaultValue: "350000000",                            Required: false, ValueType: "number", ValidationRules:"", CanAdd:false, CanUpdate: false },

        { Name: "sim.UUID",                    DefaultValue: "",                                     Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "sim.IMSI",                    DefaultValue: "",                                     Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "sim.SIMID",                   DefaultValue: "",                                     Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },

        { Name: "messaging.MSISDN",             DefaultValue: "",   Required: false, ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true },
        { Name: "messaging.BlockListEnabled",   DefaultValue: "",   Required: false, ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true },
        { Name: "messaging.BillingID",          DefaultValue: "15", Required: false, ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "messaging.BlockedNumbers",     DefaultValue: "",   Required: false, ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true },
        { Name: "messaging.Status",             DefaultValue: "",   Required: false, ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true },
        { Name: "messaging.ServiceCatalogUUID", DefaultValue: VUE_APP_PREPAY_MSG_SERVICE_UUID, Required: false, ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false },

        { Name: "voicemail.MSISDN",               DefaultValue: "",  Required: false, ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true },
        { Name: "voicemail.OutcallNumberAddPart", DefaultValue: "",  Required: false, ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true },
        { Name: "voicemail.FaxMessageEnabled",    DefaultValue: "",  Required: false, ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true },
        { Name: "voicemail.FaxMSISDN",            DefaultValue: "",  Required: false, ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true },
        { Name: "voicemail.SecondaryVoiceMSISDN", DefaultValue: "",  Required: false, ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true },
        { Name: "voicemail.NotifyEmail",          DefaultValue: "",  Required: false, ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true },
        { Name: "voicemail.PIN",                  DefaultValue: "",  Required: false, ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true },
        { Name: "voicemail.ClassOfService",       DefaultValue: "43", Required: false, ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true },
        { Name: "voicemail.ServiceCatalogUUID",   DefaultValue: VUE_APP_PREPAY_VOICEMAIL_SERVICE_UUID, Required: false, ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false },

        { Name: "ocs.ProductVariant",            DefaultValue: "",                                   Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.PurchaseBaseCost",        DefaultValue: "0",                                    Required: false, ValueType: "price",  ValidationRules:"", CanAdd:true,  CanUpdate: true },
        { Name: "ocs.RecurringBaseCost",       DefaultValue: "0",                                    Required: true,  ValueType: "price",  ValidationRules:"", CanAdd:true,  CanUpdate: true },
        { Name: "ocs.PurchaseDataPlanCost",    DefaultValue: "0",                                    Required: false, ValueType: "price",  ValidationRules:"", CanAdd:true,  CanUpdate: true },
        { Name: "ocs.RecurringDataPlanCost",   DefaultValue: "0",                                    Required: true,  ValueType: "price",  ValidationRules:"", CanAdd:true,  CanUpdate: true },
        { Name: "ocs.PurchaseRetailPrice",       DefaultValue: "0",                                  Required: false, ValueType: "price",  ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.PurchaseCreditAmount",      DefaultValue: "0",                                  Required: false, ValueType: "price",  ValidationRules:"", CanAdd:true,  CanUpdate: true  },

        { Name: "ocs.PrepaidCreditExpiryDays",   DefaultValue: "365",                                Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.PrepaidRollOverExpiryDays", DefaultValue: "365",                                Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },

        { Name: "ocs.MaxUnusedDataRollOver",     DefaultValue: "",                                   Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },

        { Name: "ocs.PrepaidDataRollOverAllowed", DefaultValue: "true",                               Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.PurchaseDataAllowance",      DefaultValue: "",                                  Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        // { Name: "ocs.RecurringDataAllowance",     DefaultValue: "",                                  Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.DataUsageRule",              DefaultValue: "Limited",                           Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },

        { Name: "ocs.PrepaidVoiceRollOverAllowed", DefaultValue: "true",                              Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.PurchaseVoiceAllowance",      DefaultValue: "",                                 Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        // { Name: "ocs.RecurringVoiceAllowance",     DefaultValue: "",                                 Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.VoiceUsageRule",              DefaultValue: "Limited",                          Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.PrepaidCUGUsageRule",         DefaultValue: "Free",                             Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.MaxUnusedVoiceRollOver",      DefaultValue: "",                                 Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },

        { Name: "ocs.PrepaidSMSRollOverAllowed",   DefaultValue: "true",                              Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.PurchaseSMSAllowance",        DefaultValue: "",                                 Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        // { Name: "ocs.RecurringSMSAllowance",        DefaultValue: "",                                 Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.SMSUsageRule",                DefaultValue: "Limited",                          Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },

        { Name: "ocs.PrepaidMMSRollOverAllowed",   DefaultValue: "true",                              Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.PurchaseMMSAllowance",        DefaultValue: "",                                 Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        // { Name: "ocs.RecurringMMSAllowance",        DefaultValue: "",                                 Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.MMSUsageRule",                DefaultValue: "Limited",                          Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },

        { Name: "ocs.PlanType",                DefaultValue: "Prepaid",                              Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.ServiceType",             DefaultValue: "Prepaid",                              Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.PlanName",                DefaultValue: "vfflex_service_pre_combo_pack",        Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.ServiceCatalogUUID",      DefaultValue: VUE_APP_PREPAY_OCS_SERVICE_UUID,        Required: false, ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false },

        { Name: "ProductName",                 DefaultValue: "",                                     Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ProductDescription",          DefaultValue: "",                                     Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },

        { Name: "ocs.SpeedLimitDL", DefaultValue: "", Required: false, ValueType: "string", ValidationRules: "", CanAdd: true, CanUpdate: true },
        { Name: "ocs.SpeedLimitUL", DefaultValue: "", Required: false, ValueType: "string", ValidationRules: "", CanAdd: true, CanUpdate: true },
        { Name: "ocs.Throttle",     DefaultValue: "", Required: false, ValueType: "string", ValidationRules: "", CanAdd: true, CanUpdate: true },
        { Name: "ocs.Tethering",    DefaultValue: "", Required: false, ValueType: "bool", ValidationRules: "", CanAdd: true, CanUpdate: true },
    ],

    prepaymobileAddon: [
        { Name: "IsBasePlan",                  DefaultValue: "false",   Required: false, ValueType: "bool", ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "RequiresAnyBasePlan",         DefaultValue: "false",   Required: false, ValueType: "bool", ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "RequiresNoBasePlan",          DefaultValue: "false",   Required: false, ValueType: "bool", ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "AllowAutoRenew",              DefaultValue: "true",  Required: false, ValueType: "bool", ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "AllowCustomerPurchase",       DefaultValue: "true",  Required: false, ValueType: "bool", ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "CustomerVisible",             DefaultValue: "false",   Required: false, ValueType: "bool", ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "ocs.OfferPeriodInterval",     DefaultValue: "days", Required: false, ValueType: "string", ValidationRules:"", CanAdd:false,  CanUpdate: false  },
        { Name: "ocs.OfferPeriodCount",        DefaultValue: "28",   Required: false, ValueType: "number", ValidationRules:"", CanAdd:false,  CanUpdate: false  },

        { Name: "cellular.IMSI",               DefaultValue: "",                                     Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "cellular.MSISDN",             DefaultValue: "",                                     Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        // { Name: "cellular.APNList",            DefaultValue: VUE_APP_PREPAY_DEFAULT_APN,             Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "cellular.DataEnabled2G",      DefaultValue: "true",                                 Required: false, ValueType: "bool",   ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "cellular.DataEnabled3G",      DefaultValue: "true",                                 Required: false, ValueType: "bool",   ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "cellular.DataEnabled4G",      DefaultValue: "true",                                 Required: false, ValueType: "bool",   ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "cellular.DataEnabled5G",      DefaultValue: "true",                                 Required: false, ValueType: "bool",   ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "cellular.ServiceCatalogUUID", DefaultValue: VUE_APP_PREPAY_CELLULAR_SERVICE_UUID,   Required: false, ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "cellular.SpeedUp",            DefaultValue: "350000000",                            Required: false, ValueType: "number", ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "cellular.SpeedDown",          DefaultValue: "350000000",                            Required: false, ValueType: "number", ValidationRules:"", CanAdd:false, CanUpdate: false },

        { Name: "sim.UUID",                    DefaultValue: "",                                     Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "sim.IMSI",                    DefaultValue: "",                                     Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "sim.SIMID",                   DefaultValue: "",                                     Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },

        { Name: "messaging.MSISDN",             DefaultValue: "",   Required: false, ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true },
        { Name: "messaging.BlockListEnabled",   DefaultValue: "",   Required: false, ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true },
        { Name: "messaging.BillingID",          DefaultValue: "15", Required: false, ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "messaging.BlockedNumbers",     DefaultValue: "",   Required: false, ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true },
        { Name: "messaging.Status",             DefaultValue: "",   Required: false, ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true },
        { Name: "messaging.ServiceCatalogUUID", DefaultValue: VUE_APP_PREPAY_MSG_SERVICE_UUID, Required: false, ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false },

        { Name: "voicemail.MSISDN",               DefaultValue: "",  Required: false, ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true },
        { Name: "voicemail.OutcallNumberAddPart", DefaultValue: "",  Required: false, ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true },
        { Name: "voicemail.FaxMessageEnabled",    DefaultValue: "",  Required: false, ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true },
        { Name: "voicemail.FaxMSISDN",            DefaultValue: "",  Required: false, ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true },
        { Name: "voicemail.SecondaryVoiceMSISDN", DefaultValue: "",  Required: false, ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true },
        { Name: "voicemail.NotifyEmail",          DefaultValue: "",  Required: false, ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true },
        { Name: "voicemail.PIN",                  DefaultValue: "",  Required: false, ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true },
        { Name: "voicemail.ClassOfService",       DefaultValue: "43", Required: false, ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true },
        { Name: "voicemail.ServiceCatalogUUID",   DefaultValue: VUE_APP_PREPAY_VOICEMAIL_SERVICE_UUID, Required: false, ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false },

        { Name: "ocs.ProductVariant",            DefaultValue: "",                                   Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.PurchaseBaseCost",          DefaultValue: "",                                   Required: false, ValueType: "price",  ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.PurchaseDataPlanCost",      DefaultValue: "0",                                  Required: false, ValueType: "price",  ValidationRules:"", CanAdd:true,  CanUpdate: true },
        { Name: "ocs.RecurringBaseCost",         DefaultValue: "",                                   Required: false, ValueType: "price",  ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.PurchaseRetailPrice",       DefaultValue: "",                                   Required: false, ValueType: "price",  ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        // { Name: "ocs.RecurringRetailPrice",      DefaultValue: "",                                   Required: false, ValueType: "price",  ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.PurchaseCreditAmount",      DefaultValue: "",                                   Required: false, ValueType: "price",  ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        // { Name: "ocs.RecurringCreditAmount",     DefaultValue: "",                                   Required: false, ValueType: "price",  ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.PrepaidCreditExpiryDays",   DefaultValue: "365",                                Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.PrepaidRollOverExpiryDays", DefaultValue: "365",                                Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },

        { Name: "ocs.MaxUnusedDataRollOver",     DefaultValue: "",                                   Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.ServiceType",            DefaultValue: "Prepaid",                            Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },

        { Name: "ocs.PrepaidDataRollOverAllowed", DefaultValue: "true",                               Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.PurchaseDataAllowance",      DefaultValue: "",                                  Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        // { Name: "ocs.RecurringDataAllowance",     DefaultValue: "",                                  Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.DataUsageRule",              DefaultValue: "Limited",                           Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },

        { Name: "ocs.PrepaidVoiceRollOverAllowed", DefaultValue: "true",                              Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.PurchaseVoiceAllowance",      DefaultValue: "",                                 Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        // { Name: "ocs.RecurringVoiceAllowance",     DefaultValue: "",                                 Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.VoiceUsageRule",              DefaultValue: "Limited",                          Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.PrepaidCUGUsageRule",         DefaultValue: "Free",                             Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.MaxUnusedVoiceRollOver",      DefaultValue: "",                                 Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },

        { Name: "ocs.PrepaidSMSRollOverAllowed",   DefaultValue: "true",                              Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.PurchaseSMSAllowance",        DefaultValue: "",                                 Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        // { Name: "ocs.RecurringSMSAllowance",        DefaultValue: "",                                 Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.SMSUsageRule",                DefaultValue: "Limited",                          Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },

        { Name: "ocs.PrepaidMMSRollOverAllowed",   DefaultValue: "true",                              Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.PurchaseMMSAllowance",        DefaultValue: "",                                 Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        // { Name: "ocs.RecurringMMSAllowance",        DefaultValue: "",                                 Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.MMSUsageRule",                DefaultValue: "Limited",                          Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },

        { Name: "ocs.PlanType",                DefaultValue: "Prepaid",                              Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.PlanName",                DefaultValue: "vfflex_service_pre_combo_pack",        Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.ServiceCatalogUUID",      DefaultValue: VUE_APP_PREPAY_OCS_SERVICE_UUID,        Required: false, ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false },

        { Name: "ProductName",                 DefaultValue: "",                                     Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ProductDescription",          DefaultValue: "",                                     Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },

        { Name: "ocs.SpeedLimitDL", DefaultValue: "", Required: false, ValueType: "string", ValidationRules: "", CanAdd: true, CanUpdate: true },
        { Name: "ocs.SpeedLimitUL", DefaultValue: "", Required: false, ValueType: "string", ValidationRules: "", CanAdd: true, CanUpdate: true },
        { Name: "ocs.Throttle",     DefaultValue: "", Required: false, ValueType: "string", ValidationRules: "", CanAdd: true, CanUpdate: true },
        { Name: "ocs.Tethering",    DefaultValue: "", Required: false, ValueType: "bool", ValidationRules: "", CanAdd: true, CanUpdate: true },
    ],

    UFB: [
        { Name: "ufb.ContactName",                        DefaultValue: "",                                     Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ufb.Address",                            DefaultValue: "",                                     Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ufb.AddressID",                          DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ufb.ServiceType",                        DefaultValue: "",                                     Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true },
        { Name: "ufb.LANIPRange",                         DefaultValue: "",                                     Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true },
        { Name: "ufb.VRFDetails",                         DefaultValue: "",                                     Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true },
        { Name: "ufb.AdditionalDetails",                  DefaultValue: "",                                     Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true },
        { Name: "ufb.DHCPScope",                          DefaultValue: "",                                     Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true },
        { Name: "ufb.SiteContactName",                    DefaultValue: "",                                     Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true },
        { Name: "ufb.SiteContactPhone",                   DefaultValue: "",                                     Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true },
        { Name: "ufb.NotificationName",                   DefaultValue: "",                                     Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true },
        { Name: "ufb.NotificationPhoneNumber",            DefaultValue: "",                                     Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true },
        { Name: "ufb.NotificationEmail",                  DefaultValue: "",                                     Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true },
        { Name: "ufb.IsManual",                           DefaultValue: "true",                                 Required: false, ValueType: "bool",   ValidationRules:"", CanAdd:true,  CanUpdate: true },
        { Name: "ufb.IPHelperDetails",                    DefaultValue: "",                                     Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true },
        { Name: "ufb.WANIP",                              DefaultValue: "",                                     Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true },
        { Name: "ufb.TermLength",                         DefaultValue: "",                                     Required: false, ValueType: "number", ValidationRules:"", CanAdd:false,  CanUpdate: false },
        { Name: "ufb.LFCPlanName",                        DefaultValue: "",                                     Required: false, ValueType: "string", ValidationRules:"", CanAdd:false,  CanUpdate: false },
        { Name: "ufb.CustomerUUID",                       DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,   CanUpdate: false },
        { Name: "ufb.ServiceCatalogUUID",                 DefaultValue: VUE_APP_UFB_SERVICE_UUID,               Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,   CanUpdate: false },
        { Name: "ufb.LFC",                                DefaultValue: "",                                     Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ufb.Chorus.ProductID",                   DefaultValue: "11",                                   Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "ufb.Chorus.Profile",                     DefaultValue: "11/100-100",                           Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "ufb.RFSDate",                            DefaultValue: "",                                     Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ufb.OrderType",                          DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ufb.CustomerName",                       DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ufb.CustomerPhoneNumber",                DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ufb.CustomerEmail",                      DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ufb.ProjectName",                        DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ufb.PrimaryOnlyOffer",                   DefaultValue: "false",                                Required: true,  ValueType: "bool",   ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "ufb.PlanMarketSegment",                  DefaultValue: "Residential",                          Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false },

        { Name: "ufb.CVID",                               DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ufb.SVID",                               DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ufb.DesiredDateTime",                    DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ufb.LocationID",                         DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ufb.OrderDate",                          DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ufb.PrequalUUID",                        DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ufb.ServiceProfile",                     DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ufb.StaticIPv4",                         DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ufb.StaticIPv6",                         DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ufb.TransferCustomerAccountID",          DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ufb.TransferCustomerInstanceID",         DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ufb.TransferCustomerName",               DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ufb.OrderDate",                          DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ufb.OrderExistingONTID",                 DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ufb.OrderExistingProductID",             DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ufb.OrderBANDWIDTH_PROFILE",             DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },

        { Name: "ufb.contact.Email",                      DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ufb.contact.Name",                       DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ufb.contact.PhoneNumber",                DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ufb.customer.Email",                     DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ufb.customer.Name",                      DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ufb.customer.PhoneNumber",               DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },

        { Name: "ufb.ActiveASSURANCE_L1_SLA_LEVEL",       DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveASSURANCE_L2_SLA_LEVEL",       DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveATA_PORT_NUMBER",              DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveBANDWIDTH_PROFILE",            DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveBillingAccount",               DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveBITSTREAM_UID_1",              DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveBITSTREAM_UID_2",              DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveBITSTREAM_UID_3",              DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveBITSTREAM_UID_4",              DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveCIRCUIT_ID_INSERTION",         DefaultValue: "OFF",                                  Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveCONNECT_RFS_DATE_TIME",        DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveDATA_HANDOVER_CONNECTION_ID",  DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveDATA_VLAN_CVID",               DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveDATA_VLAN_SVID",               DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveDISCONNECT_RFS_DATE_TIME",     DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveDOWNSTREAM_HP",                DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveDOWNSTREAM_LP_CIR",            DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveDOWNSTREAM_LP_EIR",            DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveIntactBandwidthProfile",       DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveIntactChargeType",             DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveIntactProductOfferID",         DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveIntactQuantity",               DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveIntactUnitCost",               DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveIntactValue",                  DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveITContactEmail",               DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveITContactName",                DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveITContactOrganisation",        DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveITContactPhone",               DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveMULTICLASS",                   DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveONT_ID",                       DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveORDER_DATE",                   DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveOrderContactEmail",            DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveOrderContactName",             DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveOrderContactOrganisation",     DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveOrderContactPhone",            DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveOrderId",                      DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveOrderItemId",                  DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActivePlaceDescription",             DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActivePlaceID",                      DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveProductBandwidthProfile",      DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveProductChargeType",            DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveProductID",                    DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveProductName",                  DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveProductProductOfferID",        DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveProductProductOfferName",      DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveProductQuantity",              DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveProductUnitCost",              DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveProductValue",                 DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveSiteContactEmail",             DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveSiteContactName",              DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveSiteContactOrganisation",      DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveSiteContactPhone",             DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveState",                        DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveUNI_BITSTREAM_UID_1",          DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveUNI_BITSTREAM_UID_2",          DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveUNI_BITSTREAM_UID_3",          DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveUNI_BITSTREAM_UID_4",          DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveUNI_PORT_NUMBER",              DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveUNI_PORT_TYPE",                DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveUNI_TAGGING",                  DefaultValue: "Tagged",                               Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveUNIVLAN",                      DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveUPSTREAM_HP",                  DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveUPSTREAM_LP_CIR",              DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveUPSTREAM_LP_EIR",              DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveVOICE_HANDOVER_CONNECTION_ID", DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveSERVICE_GIVEN_DATE",           DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.ActiveONTSerial",                    DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },

        { Name: "ufb.FeasabilityDATA_HANDOVER_CONNECTION_ID",  DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.FeasabilityVOICE_HANDOVER_CONNECTION_ID", DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.FeasabilityAVAILABILITY_STATUS",          DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.FeasabilityBUILD_REQUIRED",               DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.FeasabilityCONSENT_REQUIRED",             DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.FeasabilityINSTALL_TYPE",                 DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.FeasabilityDEMAND_POINT_TYPE",            DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.FeasabilityDESIGN_REQUIRED",              DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },

        { Name: "ufb.FeasabilityDESIGN_REQUIRED",              DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.FeasabilityDESIGN_REQUIRED",              DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.FeasabilityDESIGN_REQUIRED",              DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.FeasabilityDESIGN_REQUIRED",              DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.FeasabilityDESIGN_REQUIRED",              DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.FeasabilityDESIGN_REQUIRED",              DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },

    ],
}

export default {
    props: {
        readonlyMode: { type: Boolean, default: false },

        showServiceProviderPricesTable: { type: Boolean, default: true },
    },

    components: {
        baseLayout,

        appLoader,
        appError,

        appInput,
        appDropdownSelect,

        appSidebarDetails,
        characteristicDetails,
        priceDetails,

        appTable,

        dialogProductCharacteristicRemove,
        dialogProductPriceRemove,
        dialogProductRemove,
    },

    data() {
        let baseData = {
            referrer: null,
            missingChars: {},

            mask: {
                'cellular.APNList': {
                    regex: String.raw`^\S{3,255}$`,
                },
            },

            product: {
                Name: null,
                Description: null,
                ProductType: null,
            },
            original: {},

            original_characteristics: {},
            product_characteristics: {
                //
                // NB: This list is auto-populated below, so VueJS creates watchers for them
                //
            },

            dictonaries: {
                cap: [
                    { id: 'Limited',     name: 'Limited'     },
                    { id: 'Unlimited',   name: 'Unlimited'   },
                    { id: 'CasualRate ', name: 'Casual Rate' },
                ],
                on_off: [
                    { id: 'On',   name: 'On'   },
                    { id: 'Off',  name: 'Off' },
                ],
                uni_tagging: [
                    { id: 'Untagged', name: 'Untagged' },
                    { id: 'Tagged',   name: 'Tagged'   },
                    { id: '',         name: 'Empty'    },
                ],
                circuit_id_insertion: [
                    { id: 'OFF',            name: 'OFF'            },
                    { id: 'DHCP Option 82', name: 'DHCP Option 82' },
                    { id: 'PPPoE Relay',    name: 'PPPoE Relay'    },
                    { id: '',               name: 'Empty'          },
                ],
                true_false: [
                    { id: 'true',  name: 'True'  },
                    { id: 'false', name: 'False' },
                ],
                yes_no: [
                    { id: 'Yes',   name: 'Yes' },
                    { id: 'No',    name: 'No'  },
                ],
                tf_yes_no: [
                    { id: 'true',   name: 'Yes' },
                    { id: 'false',  name: 'No'  },
                ],
                biz_res: [
                    { id: 'RESIDENTIAL', name: 'Residential' },
                    { id: 'BUSINESS',    name: 'Business'    },
                    { id: 'EDUCATION',    name: 'Education'  },
                ],
                days_months: [
                    { id: 'days',   name: 'Days' },
                    { id: 'months', name: 'Months'  },
                ],
                cap_throttled : [
                    { id: 'Limited',   name: 'Suspend Data' },
                    { id: 'Unlimited', name: 'Unlimited' },
                    { id: 'Throttled', name: 'Throttled' },
                ],
                PrepaidCreditExpiryDays : [
                    { id: '30', name: '30 days' },
                    { id: '31', name: '31 days' },
                    { id: '60', name: '60 days' },
                    { id: '90', name: '90 days' },
                    { id: '180', name: '180 days' },
                    { id: '270', name: '270 days' },
                    { id: '365', name: '365 days' },
                ],
                PrepaidRollOverExpiryDays : [
                    { id: '30', name: '30 days' },
                    { id: '31', name: '31 days' },
                    { id: '60', name: '60 days' },
                    { id: '90', name: '90 days' },
                    { id: '180', name: '180 days' },
                    { id: '270', name: '270 days' },
                    { id: '365', name: '365 days' },
                ],
                PrepaidCUGUsageRule : [
                    { id: 'Free', name: 'Free' },
                    { id: 'In_Bundle', name: 'Deduct Bundle mins' },
                    { id: 'Out_Bundle', name: 'Charge normally' },
                ],
                SpeedLimitDL: [
                    { id: '', name: 'Undefined' },
                    { id: 'Unlimited', name: 'Unlimited' },
                    { id: '0.5M', name: '0.5M' },
                    { id: '1M', name: '1M' },
                    { id: '1.2M', name: '1.2M' },
                    { id: '5M', name: '5M' },
                    { id: '10M', name: '10M' },
                    { id: '15M', name: '15M' },
                    { id: '20M', name: '20M' },
                    { id: '25M', name: '25M' },
                    { id: '30M', name: '30M' },
                    { id: '40M', name: '40M' },
                    { id: '50M', name: '50M' },
                    { id: '75M', name: '75M' },
                    { id: '100M', name: '100M' },
                    { id: '150M', name: '150M' },
                    { id: '200M', name: '200M' },
                    { id: '250M', name: '250M' },
                    { id: '300M', name: '300M' },
                    // { id: 'limit1', name: 'Unlimited' },
                    // { id: 'limit2', name: '0.5M' },
                    // { id: 'limit3', name: '1M' },
                    // { id: 'limit4', name: '1.2M' },
                    // { id: 'limit5', name: '5M' },
                    // { id: 'limit6', name: '10M' },
                    // { id: 'limit7', name: '15M' },
                    // { id: 'limit8', name: '20M' },
                    // { id: 'limit9', name: '25M' },
                    // { id: 'limit10', name: '30M' },
                    // { id: 'limit11', name: '40M' },
                    // { id: 'limit12', name: '50M' },
                    // { id: 'limit13', name: '75M' },
                    // { id: 'limit14', name: '100M' },
                    // { id: 'limit15', name: '150M' },
                    // { id: 'limit16', name: '200M' },
                    // { id: 'limit17', name: '250M' },
                    // { id: 'limit18', name: '300M' },
                ],
                SpeedLimitUL: [
                    { id: '', name: 'Undefined' },
                    { id: 'Unlimited', name: 'Unlimited' },
                    { id: '0.5M', name: '0.5M' },
                    { id: '1M', name: '1M' },
                    { id: '1.2M', name: '1.2M' },
                    { id: '2M', name: '2M' },
                    { id: '5M', name: '5M' },
                    { id: '10M', name: '10M' },
                    { id: '20M', name: '20M' },
                    { id: '30M', name: '30M' },
                    { id: '40M', name: '40M' },
                    { id: '50M', name: '50M' },
                    // { id: 'limit1', name: 'Unlimited' },
                    // { id: 'limit2', name: '0.5M' },
                    // { id: 'limit3', name: '1M' },
                    // { id: 'limit4', name: '1.2M' },
                    // { id: 'limit5', name: '2M' },
                    // { id: 'limit6', name: '5M' },
                    // { id: 'limit7', name: '10M' },
                    // { id: 'limit8', name: '20M' },
                    // { id: 'limit9', name: '30M' },
                    // { id: 'limit10', name: '40M' },
                    // { id: 'limit11', name: '50M' },v
                ],
                Throttle: [
                    { id: '', name: 'Undefined' },
                    { id: 'No', name: 'Unlimited' },
                    { id: 'Throttle1.2M1.2M', name: '1.2 DL / 1.2 UL' },
                    { id: 'Throttle2M2M', name: '2 DL / 2 UL' },
                ],
            },
            
            characteristics: {
                list: [],
                selected: null,

                cols: [
                    { key: 'name',       title: 'Name',             highlight: true      },
                    { key: 'default',    title: 'Default Value'                          },
                    { key: 'required',   title: 'Required'                               },
                    { key: 'value_type', title: 'Value Type'                             },
                    { key: 'can_add',    title: 'Can Add'                                },
                    { key: 'can_update', title: 'Can Update'                             },
                ],
            },

            prices: {
                list: [],
                selected: null,

                cols: [
                    { key: 'provider',    title: 'Service Provider',   highlight: true      },
                    { key: 'available',   title: 'Avail'                                },
                    { key: 'price',       title: 'Price'                                    },
                    { key: 'valid_from',  title: 'Valid From'                               },
                    { key: 'purchase_to', title: 'Purchase To'                        },
                    { key: 'version_to',  title: 'Valid To'                         },
                    { key: 'priority',    title: 'Prio'                                 },

                    { action: 'details', icon: 'edit',                 behavior: 'hidden'   },
                    { action: 'remove',  icon: 'remove',               behavior: 'detached' },
                ],
            },

            dialogs: {
                characteristic: {
                    remove: {
                        show: false,
                        uuid: null,
                    },
                },
                price: {
                    remove: {
                        show: false,
                        uuid: null,
                    },
                },
                remove: {
                    show: false,
                    uuid: null,
                },
            },

            providers_spids: {},

            loading: {
                product: false,
                characteristics: false,
                prices: false,
            },
            processing: {
                product: false,
                data: false,
                voice: false,
                sms: false,
                mms: false,
                plan_ufb: false,
                throttling_speed_limits: false,
                mobile_esp: false,
            },

            errors: {},
        }

        for (const prodName in PER_PRODUCT_CHARACTERISTICS) {
            const thisProduct = PER_PRODUCT_CHARACTERISTICS[prodName]
            for (const i in thisProduct) {
                if (!thisProduct[i] || !thisProduct[i].Name) {
                    continue
                }
                baseData.product_characteristics[thisProduct[i].Name] = {}
                // baseData.product_characteristics[thisProduct[i].Name] = {
                //     DefaultValue: null,
                // }
                // console.log('thisProduct[i].Name', thisProduct[i].Name)
            }
        }

        for (const groupName in CHARACTERISTIC_GROUPS) {
            const thisGroup = CHARACTERISTIC_GROUPS[groupName]
            // console.log('groupName', groupName)
            for (const thisChar in thisGroup) {
                // console.log('  thisChar', thisChar)
                if (!thisGroup[thisChar]) {
                    continue
                }
                baseData.product_characteristics[thisChar] = {}
                // baseData.product_characteristics[thisChar] = {
                //     DefaultValue: null,
                // }
                // console.log('thisChar', thisChar)
            }
        }

        return baseData
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            if (this.is_editing) {
                this.getProductInfo()

                if (!this.readonlyMode) {
                    this.characteristics.cols.push({ action: 'details', icon: 'edit',              behavior: 'hidden'   })
                    this.characteristics.cols.push({ action: 'remove',  icon: 'remove',            behavior: 'detached' })
                }
            } else {
                this.product.ProductType = this.plan_type

                this.original = {...this.product}
                this.setCharDefaults(true, true)
            }
        },

        // Set default values for characteristics
        setCharDefaults(overrideValues, setRegardless) {

            let thisProductType = this.type
            if (!thisProductType) {
                thisProductType = this.product.ProductType
            }
            if (!thisProductType) {
                console.log('setCharDefaults WARNING: no type')
                return
            }
            // Set this product types' defaults
            const thisProductChars = PER_PRODUCT_CHARACTERISTICS[this.type]
            // console.log('thisProductChars', thisProductChars)
            for (const charnum in thisProductChars) {
                if (thisProductChars[charnum].Name && (setRegardless || thisProductChars[charnum].DefaultValue)) {
                    if (overrideValues || !this.product_characteristics[thisProductChars[charnum].Name]) {
                        this.product_characteristics[thisProductChars[charnum].Name] = {...thisProductChars[charnum]}
                        // console.log("prodType " + thisProductChars[charnum].Name + " = " + thisProductChars[charnum].DefaultValue)
                    }
                }
            }
        },


        /** Product */
        
        getProductInfo() {
            this.loading.product = true
            
            this.$store.dispatch('api_product_catalog/getCachedProductCatalogEntryByUUID', { UUID: this.uuid }).then(product => {
                // this.setProduct(product)

                this.getProductСharacteristics().then(characteristics => {
                    this.setProduct(product, characteristics)
                    this.loading.product = false
                })
                this.getProductPrices()

                // this.loading.product = false
            }).catch(error => {
                this.$store.dispatch('addToast', {
                    message: errMessage(error),
                    type: 'error',
                    delay: 5000,
                })

                console.log('Error api_product_catalog/getCachedProductCatalogEntryByUUID', error)
                console.dir(error)

                this.$router.push({
                    ...this.back_to,
                    replace: true,
                })

                this.loading.product = false
            })
        },
        
        setProduct(product, characteristics) {
            // console.group('setProduct(product, characteristics) {')
            // console.log('product', {...product})
            // console.log('characteristics', [...characteristics])

            this.product.Name = product.Name
            this.product.Description = product.Description
            this.product.ProductType = product.ProductType

            // Set characteristics default values
            this.setCharDefaults(true, true)

            let justProductChars = {}
            this.missingChars = {}
            // Move array into a map
            for (let i = 0, len = characteristics.length; i < len; i++) {
                const characteristic = characteristics[i]
                this.product_characteristics[characteristic.Name] = characteristic
                justProductChars[characteristic.Name] = characteristic
            }            

            // Check for missing characteristics
            const thisProductChars = PER_PRODUCT_CHARACTERISTICS[product.ProductType]
            for (const charnum in thisProductChars) {
                if (!thisProductChars[charnum].Name) {
                    continue
                }
                if (!(thisProductChars[charnum].Name in justProductChars)) {
                    console.log('we are missing', thisProductChars[charnum].Name)
                    this.missingChars[thisProductChars[charnum].Name] = {...thisProductChars[charnum]}
                    continue
                }
            }

            for (const charName in this.product_characteristics) {
                if (!this.valid_characteristic(charName)) {
                    continue
                }
                if (charName in CENTS_CHARACTERISTICS) {
                    if (this.product_characteristics[charName] && this.product_characteristics[charName].DefaultValue && this.product_characteristics[charName].DefaultValue.startsWith('$')) {
                        this.product_characteristics[charName].DefaultValue = this.product_characteristics[charName].DefaultValue.substring(1)
                    }
                    this.product_characteristics[charName].DefaultValue = '$' + (this.product_characteristics[charName].DefaultValue / 100).toFixed(2)
                }
                // This method is required for deep copying...
                this.original_characteristics[charName] = {...this.product_characteristics[charName]}
            }

            this.original = {...this.product}

            // console.log('this.product', {...this.product})
            // console.groupEnd()
        },

        removeProduct() {
            this.dialogs.remove.show = true
            this.dialogs.remove.uuid = this.uuid
        },

        onProductRemoved() {
            if (this.on_sidebar) {
                this.$emit('close-sidebar')
            } else {
                this.$router.push({
                    ...this.back_to,
                    replace: true,
                })
            }
        },

        fixchars() {
            for (const key in this.processing) {
                this.processing[key] = true
            }
            let all_char_adds = []
            for (const charName in this.missingChars) {
                const payload = {
                    ...this.missingChars[charName],
                    ProductCatalogEntryUUID: this.uuid,
                }
                console.log('Adding', payload)
                const request = this.$store.dispatch('api_product_catalog/AddProductCatalogCharacteristic', payload).then(characteristic => {
                    if (charName in CENTS_CHARACTERISTICS) {
                        if (characteristic && characteristic.DefaultValue && characteristic.DefaultValue.startsWith('$')) {
                            characteristic.DefaultValue = characteristic.DefaultValue.substring(1)
                        }
                        characteristic.DefaultValue = '$' + (characteristic.DefaultValue / 100).toFixed(2)
                    }
                    this.product_characteristics[ charName ] = characteristic
                    this.original_characteristics[ charName ] = { ...characteristic }
                })
                .catch(error => {
                    this.errors[ charName ] = errMessage(error)
                })
                all_char_adds.push(request)
            }
            if (all_char_adds.length) {
                Promise.all(all_char_adds)
                    .then(() => {
                        this.missingChars = {}
                    })
                    .catch(()=>{})
                    .finally(() => {
                        for (const key in this.processing) {
                            this.processing[key] = false
                        }
                    })
            } else {
                for (const key in this.processing) {
                    this.processing[key] = false
                }
            }

        },

        save(target) {
            const saveAllCharacteristics = product => {
                let requests_all = []

                for (const charName in this.product_characteristics) {
                    if (!this.valid_characteristic(charName)) {
                        continue
                    }

                    let defaultValueToSave = this.product_characteristics[charName].DefaultValue

                    if (charName in CENTS_CHARACTERISTICS) {
                        if (defaultValueToSave && defaultValueToSave.startsWith('$')) {
                            defaultValueToSave = defaultValueToSave.substring(1)
                        }

                        defaultValueToSave = Math.floor(defaultValueToSave * 100).toString()
                    }

                    const params = {
                        ...this.product_characteristics[charName],
                        ProductCatalogEntryUUID: product.UUID,
                        DefaultValue: defaultValueToSave,
                    }

                    const request = this.$store.dispatch('api_product_catalog/AddProductCatalogCharacteristic', params)
                        .then(characteristic => {
                            if (charName in CENTS_CHARACTERISTICS) {
                                if (characteristic && characteristic.DefaultValue && characteristic.DefaultValue.startsWith('$')) {
                                    characteristic.DefaultValue = characteristic.DefaultValue.substring(1)
                                }

                                characteristic.DefaultValue = '$' + (characteristic.DefaultValue / 100).toFixed(2)
                            }

                            this.product_characteristics[ charName ] = characteristic
                            this.original_characteristics[ charName ] = { ...characteristic }
                        })
                        .catch(error => {
                            this.errors[ charName ] = errMessage(error)
                        })

                    requests_all.push(request)
                }

                if (requests_all.length) {
                    Promise.all(requests_all)
                        .then(() => {})
                        .catch(()=>{})
                        .finally(() => {
                            for (const groupname in CHARACTERISTIC_GROUPS) {
                                this.processing[groupname] = false
                            }

                            this.uuid = product.UUID

                            this.$router.push({ name: 'product', params: { uuid: product.UUID } })

                            this.$store.dispatch('addToast', {
                                message: `Product "${ product.Name }" was successfully created`,
                                type: 'success',
                                delay: 5000,
                            })
                        })
                } else {
                    for (const groupname in CHARACTERISTIC_GROUPS) {
                        this.processing[groupname] = false
                    }
                }
            }

            const saveTargetCharacteristics = (target) => {
                let requests_this_target = []
                for (const charName in CHARACTERISTIC_GROUPS[target]) {
                    if (!this.valid_characteristic(charName)) {
                        continue
                    }

                    if (this.original_characteristics[charName] && this.product_characteristics[charName].DefaultValue == this.original_characteristics[charName].DefaultValue) {
                        // skip over ones that haven't changed
                        continue
                    }
                    const action = this.product_characteristics[charName].UUID
                        ? 'api_product_catalog/UpdateProductCatalogCharacteristic'
                        : 'api_product_catalog/AddProductCatalogCharacteristic'
                    if (this.product_characteristics[charName].ProductCatalogEntryUUID == '') {
                        this.product_characteristics[charName].ProductCatalogEntryUUID = this.product.UUID
                    }

                    let defaultValueToSave = this.product_characteristics[charName].DefaultValue
                    if (charName in CENTS_CHARACTERISTICS) {
                        if (defaultValueToSave && defaultValueToSave.startsWith('$')) {
                            defaultValueToSave = defaultValueToSave.substring(1)
                        }
                        defaultValueToSave = Math.floor(defaultValueToSave * 100).toString()
                    }
                    const request = this.$store.dispatch(action, {
                        ...this.product_characteristics[charName],
                        DefaultValue: defaultValueToSave,
                    }).then(characteristic => {
                        if (charName in CENTS_CHARACTERISTICS) {
                            if (characteristic && characteristic.DefaultValue && characteristic.DefaultValue.startsWith('$')) {
                                characteristic.DefaultValue = characteristic.DefaultValue.substring(1)
                            }
                            characteristic.DefaultValue = '$' + (characteristic.DefaultValue / 100).toFixed(2)
                        }
                        this.product_characteristics[ charName ] = characteristic
                        this.original_characteristics[ charName ] = { ...characteristic }
                        // return Promise.resolve(characteristic)
                    }).catch(error => {
                        this.errors[ charName ] = errMessage(error)
                    })
                    requests_this_target.push(request)
                }

                if (requests_this_target.length) {
                    Promise.all(requests_this_target)
                        .then(() => {
                        })
                        .catch(()=>{})
                        .finally(() => {
                            this.processing[target] = false
                        })
                } else {
                    this.processing[target] = false
                }

                // console.group('const saveCharacteristics = (product) => {')
                // console.log('product', {...product})
                // console.log('this.product', {...this.product})
            }

            if (this.is_new) {
                if (this.validation('all')) {
                    for (const groupname in CHARACTERISTIC_GROUPS) {
                        this.processing[groupname] = true
                    }

                    for (const key in this.errors) {
                        console.log('New Product error:', key)
                        this.$delete(this.errors, key)
                    }

                    if (this.product_characteristics['ocs.ProductVariant'] && this.product_characteristics['ocs.ProductVariant'].DefaultValue == '') {
                        this.product_characteristics['ocs.ProductVariant'].DefaultValue = this.product.Name
                    }

                    const params = {
                        Name: this.product.Name,
                        ProductType: this.product.ProductType,
                        Description: this.product.Description,
                    }

                    this.$store.dispatch('api_product_catalog/AddProductCatalogEntry', params)
                        .then(saveAllCharacteristics)
                        .catch(error => {
                            this.errors.product = errMessage(error)

                            for (const key in this.processing) {
                                this.processing[key] = false
                            }
                        })
                }
            } else {
                if (this.validation(target)) {
                    this.processing[target] = true

                    if (target == 'product') {
                        const payload = {}

                        payload.UUID = this.uuid

                        this.$store.dispatch('api_product_catalog/UpdateProductCatalogEntry', {
                            UUID: this.uuid,
                            Name: this.product.Name,
                            // Name: this.product.ProductVariant,
                            // ProductType: this.product.ProductType,
                            Description: this.product.Description,
                        })
                        .then((product) => {
                            if (!this.product.UUID || this.product.UUID == '') {
                                this.product.UUID = product.UUID
                            }
                            saveTargetCharacteristics('product')
                        })
                        .catch(error => {
                            this.errors.product = errMessage(error)
                            this.processing.product = false
                        })

                    } else {
                        saveTargetCharacteristics(target)
                    }
                }
            }


            // api_product_catalog/AddProductCatalogEntry
            // api_product_catalog/AddProductCatalogCharacteristic

            // console.groupEnd()
        },

        validation(target) {
            // console.group('validation(target) {')
            console.log('target', target)
            // console.log('this.product', {...this.product})

            let is_valid = true
            this.errors = {}

            let values = {}

            if (target == 'product' || target == 'all') {
                values.Name = { DefaultValue: this.product.Name }
                values.Description = { DefaultValue: this.product.Description }
                values.ProductType = { DefaultValue: this.product.ProductType }
            }

            if (!CHARACTERISTIC_GROUPS[target] && target != 'all') {
                console.log('WARNING: Unable to validate target ', target)
                return true
            }

            if (target == 'all') {
                for (const groupname in this.available_characteristics_by_ui_group) {      
                    for (const charName in this.available_characteristics_by_ui_group[groupname]) {
                        if (!this.valid_characteristic(charName)) {
                            continue
                        }
                        // console.log('groupname', groupname, 'charName', charName)
                        if (this.product_characteristics[charName] != this.original_characteristics[charName] || ProductsValidator.isRequired(charName)) {
                            values[charName] = this.product_characteristics[charName]
                        }
                    }
                }
            } else {
                for (const charName in this.available_characteristics_by_ui_group[target]) {
                    if (!this.valid_characteristic(charName)) {
                        continue
                    }
                    // console.log('charName', charName)
                    if (this.product_characteristics[charName] != this.original_characteristics[charName] || ProductsValidator.isRequired(charName)) {
                        values[charName] = this.product_characteristics[charName]
                    }
                }
            }

            console.log('values', values)

            for (const field in values) {
                // console.group(field)
                // console.log('ProductsValidator.isRuleExists(field)', ProductsValidator.isRuleExists(field))

                if (ProductsValidator.isRuleExists(field)) {
                    const error = ProductsValidator.isInvalid(field, values[field].DefaultValue)

                    if (error) {
                        console.log('validation error on', field, ':', error)
                        this.$set(this.errors, field, error)
                        // console.warn('this.errors', field, error)
                        is_valid = false
                    }
                }

                // console.groupEnd()
            }

            // console.log('>>> is_valid', is_valid)
            // console.groupEnd()

            return is_valid
        },



        /** Characteristics */

        getProductСharacteristics() {
            this.loading.characteristics = true

            return this.$store.dispatch('api_product_catalog/FindProductCatalogCharacteristicsPaginated', { ProductCatalogEntryUUID: this.uuid }).then(response => {
                this.characteristics.list = response.ProductCatalogCharacteristics.filter(item => !(item.Name in this.product))
                // console.warn('this.characteristics.list', this.characteristics.list)

                this.loading.characteristics = false

                return Promise.resolve(response.ProductCatalogCharacteristics)
            }).catch(error => {
                this.$store.dispatch('addToast', {
                    message: errMessage(error),
                    type: 'error',
                    delay: 5000,
                })

                console.log('Error api_product_catalog/FindProductCatalogCharacteristicsPaginated', error)
                console.dir(error)

                this.characteristics.list = []

                this.loading.characteristics = false

                return Promise.resolve([])
            })
        },

        showCharacteristicDetails(char) {
            if (this.characteristics.selected === char && this.prices.selected === null) {
                this.closeSidebar()
            } else {
                this.characteristics.selected = char
                this.prices.selected = null
                this.openSidebar()
            }
        },
        openCharacteristicDetails(char) {
            this.$router.push({ name: 'product-characteristic', params: { uuid: this.uuid, char_uuid: char.uuid } })
        },

        addCharacteristics() {
            if (document.body.clientWidth <= this.$mobile_size) {
                this.$router.push({ name: 'product-characteristic', params: { uuid: this.uuid } })
            } else if (this.characteristics.selected !== null && !Object.keys(this.characteristics.selected).length && this.prices.selected === null) {
                this.closeSidebar()
            } else {
                this.characteristics.selected = {}
                this.prices.selected = null
                this.openSidebar()
            }
        },
        onUpdatedCharacteristic(char) {
            this.closeSidebar()

            // this.product_characteristics[char.Name] = char
            // this.$set(this.product_characteristics, char.Name, char)

            this.$router.go()

            // this.getProductСharacteristics()
        },
        onAddedCharacteristic(char) {
            this.closeSidebar()

            // this.characteristics.list = []
            // this.characteristics.list.push(char)

            this.$router.go()
            // this.getProductСharacteristics().then(() => {
            //     this.characteristics.selected = this.characteristics_list.find(item => item.uuid == char.UUID)
            // })
        },

        removeCharacteristic(char) {
            this.dialogs.characteristic.remove.show = true
            this.dialogs.characteristic.remove.uuid = char.uuid
        },
        onCharacteristicRemoved() {
            this.closeSidebar()
            this.$router.go()
            return
            if (this.characteristics.selected && this.characteristics.selected.uuid == this.dialogs.characteristic.remove.uuid) {
                this.characteristics.selected = null
                this.closeSidebar()
            }
            this.dialogs.characteristic.remove.uuid = null

            this.getProductСharacteristics()
        },



        /** Prices */
        
        getProductPrices() {
            this.loading.prices = true

            const getProductPrices = () =>
                this.$store.dispatch('api_product_catalog/FindProductCatalogAvailabilitiesPaginated', { ProductCatalogEntryUUID: this.uuid, 'SearchOptions.PageSize': -1 }).then(response => {
                    this.prices.list = response.ProductCatalogAvailabilities

                    this.loading.prices = false
                }).catch(error => {
                    this.$store.dispatch('addToast', {
                        message: errMessage(error),
                        type: 'error',
                        delay: 5000,
                    })

                    console.log('Error FindProductCatalogAvailabilitiesPaginated', error)
                    console.dir(error)

                    this.prices.list = []

                    this.loading.prices = false
                })

            return this.$store.dispatch('api_serviceprovider/FindServiceProvidersPaginated', { 'SearchOptions.PageSize': -1 }).then(response => {
                    const providers = response.ServiceProviders
                    
                    for (let i = 0, len = providers.length; i < len; i++) {
                        const provider = providers[i]

                        this.providers_spids[ provider.SPID ] = provider
                    }

                    return getProductPrices()
                }).catch(getProductPrices)
        },

        showPriceDetails(price) {
            if (this.characteristics.selected === null && this.prices.selected === price) {
                this.closeSidebar()
            } else {
                this.characteristics.selected = null
                this.prices.selected = price
                this.openSidebar()
            }
        },
        openPriceDetails(price) {
            this.$router.push({ name: 'product-price', params: { uuid: this.uuid, price_uuid: price.uuid } })
        },

        addPrice() {
            if (document.body.clientWidth <= this.$mobile_size) {
                this.$router.push({ name: 'product-price', params: { uuid: this.uuid } })
            } else if (this.characteristics.selected === null && this.prices.selected !== null && !Object.keys(this.prices.selected).length) {
                this.closeSidebar()
            } else {
                this.characteristics.selected = null
                this.prices.selected = {}
                this.openSidebar()
            }
        },
        onUpdatedPrice(price) {
            this.getProductPrices()
        },
        onAddedPrice(price) {
            this.getProductPrices().then(() => {
                this.prices.selected = this.prices_list.find(item => item.uuid == price.UUID)
            })
        },

        removePrice(price) {
            this.dialogs.price.remove.show = true
            this.dialogs.price.remove.uuid = price.uuid
        },
        onPriceRemoved() {
            if (this.prices.selected && this.prices.selected.uuid == this.dialogs.price.remove.uuid) {
                this.prices.selected = null
                this.closeSidebar()
            }
            this.dialogs.price.remove.uuid = null

            this.getProductPrices()
        },



        /** Sidebar */
        openSidebar() {
            this.$emit('open-sidebar')
        },

        closeSidebar() {
            switch (true) {
                case this.sidebar_characteristic: {
                    this.characteristics.selected = null
                } break

                case this.sidebar_price: {
                    this.prices.selected = null
                } break
            }

            this.$emit('close-sidebar')
        },

        price(cents) {
            return `$${ ( cents / 100 ).toFixed(2) }`
        },

        valid_characteristic(charName) {
            return (charName in this.product_characteristics) &&
                  typeof this.product_characteristics[charName] === 'object' &&
                  this.product_characteristics[charName] !== null &&
                  this.product_characteristics[charName].Name
        },

        show_characteristic(group, charName) {
            return this.available_characteristics_by_ui_group &&
                this.available_characteristics_by_ui_group[group] && 
                (charName in this.available_characteristics_by_ui_group[group]) &&
                this.valid_characteristic(charName)
        },

        show_characteristic_group(group) {
            if (this.available_characteristics_by_ui_group && this.available_characteristics_by_ui_group[group]) {
                const thisGroup = this.available_characteristics_by_ui_group[group]
                // Check to see if we have at least 1 characteristic in the given group
                for (const charName in thisGroup) {
                    if (this.show_characteristic(group, charName)) {
                        return true
                    }
                }                
            }
            return false
        },

        getCharsFlat(availability) {
            if (!availability || !availability.OverrideCharacteristics) { return '' }
            
            let resp = ''
            for (const key in availability.OverrideCharacteristics) {
                // if (key == '' || availability.OverrideCharacteristics[key] == '') {
                //     continue
                // }
                if (resp != '') {
                    resp = ', ' + resp
                }
                resp = resp + key + ':' + availability.OverrideCharacteristics[key]
            }
            return resp
        },

        getProviderName(availability) {
            let spName = '?'
            if (availability.SPID in this.providers_spids) {
                spName = this.providers_spids[availability.SPID].Name
            }
            const charsFlat = this.getCharsFlat(availability)
            if (charsFlat != '') {
                spName = spName + ' (' + charsFlat + ')'
            }
            return spName
        },

    },

    computed: {
        ...mapGetters([
            'is_super_admin',

            'products_adding_types',
            'is_prerelease_mode',

            '$mobile_size',
        ]),

        masks() {
            return {
                'ufb.TermLength': {
                    regex: '[0-9]{1,10}',
                },

                'ocs.RecurringBaseCost': {
                    regex: String.raw`\d+([.,]\d+)?`,
                },
            }
        },

        charsNeedFixing() {
            return this.uuid && (Object.keys(this.missingChars).length > 0)
        },

        back_to() {
            return this.referrer && (this.referrer.name == 'products' || this.referrer.name == 'service-provider-products')
                ? this.referrer
                : this.is_super_admin
                    ? { name: 'products' }
                    : { name: 'service-provider-products' }
        },

        uuid() {
            return this.$route.params.type in this.products_adding_types
                ? null
                : this.$route.params.uuid
        },

        is_new() {
            return !this.is_editing
        },
        is_editing() {
            return Boolean(this.uuid)
        },

        type() {
            return this.$route.params.type
                || this.product.ProductType
        },

        plan_type() {
            return this.type in this.products_adding_types
                ? this.products_adding_types[this.type].plan_type
                : this.type
        },

        is_base_product_changed() {
            if ((this.product.Name != this.original.Name) ||
                (this.product.ProductType != this.original.ProductType) ||
                (this.product.Description != this.original.Description)) {
               return true
            }
            // for (const charName in CHARACTERISTIC_GROUPS['product']) {
            // }
            return false
        },

        is_changed() {
            // console.group('is_changed() {')

            let is_changed = {
                product: this.is_base_product_changed,
                data: false,
                voice: false,
                sms: false,
                mms: false,
                prepay_credit: false,
                prepay_purchase: false,
                plan_ufb: false,
                throttling_speed_limits: false,
                mobile_esp: false,
            }

            for (const groupname in CHARACTERISTIC_GROUPS) {
                // console.log(groupname)
                for (const charName in CHARACTERISTIC_GROUPS[groupname]) {
                    // console.log("  ",charName,":", this.product_characteristics[charName])
                    if (!this.valid_characteristic(charName)) {
                        continue
                    }
                    if (!this.original_characteristics[charName]) {
                        // If we are missing original characteristics, then we are changed
                        is_changed[groupname] = true
                        // console.log('Characteristic does exist in the original version of this product -', charName)
                        break
                    }
                    if (this.original_characteristics[charName].DefaultValue != this.product_characteristics[charName].DefaultValue) {
                        // its changed
                        is_changed[groupname] = true
                        console.log('Characteristic has changed - ', charName, 'to', this.product_characteristics[charName].DefaultValue)
                        break
                    }
                    // console.log('No change - ', charName, ' => ', this.original_characteristics[charName].DefaultValue, this.product_characteristics[charName].DefaultValue)
                }
            }

            return is_changed
        },

        available_characteristics_by_ui_group() {
            if (this.type in PER_PRODUCT_UI_ELEMENTS) {
                return PER_PRODUCT_UI_ELEMENTS[this.type]
            }
            // console.log('Unsupported type:', this.type)
            return PER_PRODUCT_UI_ELEMENTS['UNKNOWN']
        },

        characteristics_list() {
            let characteristics_list = []
            // for (const uuid in this.characteristics.list) {
            for (const charName in this.product_characteristics) {
                if (!this.valid_characteristic(charName)) {
                    continue
                }
                const item = this.product_characteristics[charName]

                characteristics_list.push({
                    uuid: item.UUID,
                    name: item.Name,
                    default: item.DefaultValue || '(blank)',
                    required: item.Required ? 'Yes' : 'No',
                    value_type: item.ValueType || '(none)',
                    can_add: item.CanAdd ? 'Yes' : 'No',
                    can_update: item.CanUpdate ? 'Yes' : 'No',
                })
            }

            return characteristics_list
        },

        prices_list() {
            return this.prices.list.map(item => ({
                uuid: item.UUID,
                provider: this.getProviderName(item),
                available: item.Available ? 'Yes' : 'No',
                price: this.price(item.PriceOverride),
                valid_from: item.ValidFromNanos ? formatDateNano(item.ValidFromNanos) : '-',
                purchase_to: item.PurchaseValidToNanos ? formatDateNano(item.PurchaseValidToNanos) : '-',
                version_to: item.VersionValidToNanos ? formatDateNano(item.VersionValidToNanos) : '-',
                chars: this.getCharsFlat(item),
                priority: ''+item.Priority,
            }))
        },

        sidebar_title() {
            return this.sidebar_price ? `Price details` : 'Characteristics details'
        },
        sidebar_characteristic() {
            return Boolean(this.characteristics.selected)
        },
        sidebar_price() {
            return !this.sidebar_characteristic && Boolean(this.prices.selected)
        },
    },

    beforeRouteUpdate(to, from, next) {
        this.closeSidebar()
        next()
    },

    beforeRouteLeave(to, from, next) {
        this.closeSidebar()
        next()
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.referrer = from
        })
    },
}
</script>

<style lang="scss">
#page-product-creating {
    margin: #{ 24px - 56px } 0 80px;

    .btn-back-to {
        margin-bottom: 12px;
    }

    .header-info {
        display: flex;
        justify-content: space-between;
        width: fit-content;
        min-height: 62px;
        margin-bottom: 40px;
    }

    .col-12 {
        margin-top: 15px;

        &:first-child { margin: 0 };

        &.characteristics {
            display: flex;
            justify-content: stretch;
            align-items: stretch;
            flex-wrap: wrap;
            padding: 0;

            .board {
                flex-grow: 1;
                width: calc(50% - 30px);
                min-width: 365px;
                margin: 15px;
            }
        }

        &.actions {
            display: flex;
            justify-content: flex-end;

            margin-bottom: 15px;

            .btn {
                margin-right: 30px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .row {
        &.actions {
            .col-12 {
                margin-top: 30px;

                &.actions {
                    justify-content: space-between;
                }
            }
        }
    }

    .gap {
        margin-top: 50px;

        &:nth-child(3) {
            display: none;
        }
    }

    .board {
        padding: 24px;
        border-radius: $border-radius-secondary;
        background: var(--color-component-bg-primary);
        box-shadow: var(--box-shadow-primary);
        position: relative;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        .app-error {
            margin-top: 16px;
        }

        .input-line-dbl {
            width: 770px;
            max-width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &:empty {
                display: none;
            }

            .app-input {
                width: calc(50% - 15px);
            }
            .app-dropdown-select {
                width: calc(50% - 15px);
            }
        }

        .app-input,
        .app-dropdown-select {
            margin: 24px 0 0;
            width: 770px;
            max-width: 100%;
        }

        .btns {
            flex-grow: 1;
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
        }

        &>.btn,
        &+.btn,
        &>.btns .btn {
            margin-top: 32px;
        }

        .input-line-dbl {
            .btn {
                margin-top: 32px;
            }
        }
    }

    .characteristics-table {
        margin-top: 15px;

        &.readonly-mode {
            @include table-cols-width((250px, 150px, 110px, 150px, 110px, 110px), true);
        }

        &.edit-mode {
            @include table-cols-width((250px, 150px, 110px, 150px, 110px, 110px, 48px, 48px), true, 2);
        }
    }

    .prices-table {
        margin-top: 15px;

        @include table-cols-width((320px, 60px, 90px, 160px, 160px, 160px, 60px, 48px, 48px), true, 2);
    }

    .prices-table {
        margin-top: 30px;
    }
}

@media (max-width: $tablet-size) {
    #page-product-creating {
        .header-info {
            width: 100%;
            margin-bottom: 48px;
            min-height: 56px;
        }

        .board {
            padding: 16px;
        }
        
        .characteristics-table {
            &.readonly-mode {
                @include table-cols-width((150px, 110px, 90px, 120px, 90px, 90px), true);
            }

            &.edit-mode {
                @include table-cols-width((150px, 110px, 90px, 120px, 90px, 90px, 40px, 40px), true, 2);
            }
        }

        .prices-table {
            @include table-cols-width((180px, 60px, 70px, 130px, 130px, 130px, 60px, 40px, 40px), true, 2);
        }
    }
}

@media (max-width: $mobile-size) {
    #page-product-creating {
        margin: #{ 24px - 56px } 0 64px;

        .btn-back-to {
            font-size: 16px;
            line-height: 18px;
            margin-bottom: 8px;
        }

        .header-info {
            flex-direction: column;
            margin-bottom: 32px;
            min-height: 40px;
        }

        .col-12 {
            &.characteristics {
                margin-top: #{ 15px * .5 };

                .board {
                    width: 100%;
                    min-width: 0;
                    margin: #{ 15px * .5 };
                }
            }
        }

        .board {
            padding: 16px 8px 8px;

            &>.btn,
            &>.btns .btn {
                max-width: 100%;
            }

            &+.btn {
                margin-top: 15px;
            }
        }

        .characteristics-table {
            margin-top: #{ 15px * .5 };

            @include table-cols-width-mobile((85px, 160px, 24px), true);
        }

        .prices-table {
            @include table-cols-width-mobile((85px, 160px, 24px), true);
        }

        .prices-table {
            margin-top: 15px;
        }
    }
}
</style>
