import ValidatorClass from './ValidatorClass'

export default new ValidatorClass({
    provider: {
        name: 'Service Provider',
        required: true,
    },
    price: {
        name: 'Price',
        format: /^\d{0,5}(\.\d{0,2})?$/, 
    },
    priority: {
        name: 'Priority',
        format: /^\d{0,4}$/, 
    },
})