<template>
    <div class="app-input app-input-timepicker"
        :class="{ disabled, error, required, typing, focused, [textTransform]: textTransform, [child_class]: child_class }"
        @click="focus"
    >
        <div class="icon"></div>

        <timepicker
            :value="value"

            :format="format"
            :minute-interval="minuteInterval"
            :second-interval="secondInterval"

            :disabled="disabled"
            
            @focus="handleFocus"
            @blur="handleBlur"

            @input="onEnter"

            ref="field"
        ></timepicker>

        <label v-if="!error"><span>{{ label }}<i v-if="required">*</i></span></label>

        <div class="error-message" v-if="error">{{ error }}</div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appInput from '@/components/app-input'

import timepicker from 'vue2-timepicker/src'

export default {    
    extends: appInput,

    props: {
        format: { type: String, default: 'HH:mm' },

        minuteInterval: { type: Number, default: 5 },
        secondInterval: { type: Number, default: 10 },
    },

    components: {
        timepicker,
    },

    methods: {
        focus() {
            return this
        },

         onEnter(date) {
            this.$emit('input', date)
            this.$emit('change', date)
            this.clearDebounce()
        },
    },

    computed: {
        typing: function () {
            return this.focused || ( this.value && (this.value.HH || this.value.mm) )
        },
    }
}
</script>

<style lang="scss">
$timepicker-hint-height: 24px;
$timepicker-time-height: 32px;
$timepicker-height: $timepicker-hint-height + $timepicker-time-height * 4;

.app-input.app-input-timepicker {
    .vue__time-picker {
        width: 100%;
        font: inherit;

        input {
            display: block;
            width: 100%;
            // height: 100%;
            height: 48px;

            padding: 14px;

            font-size: 16px;
            font-weight: normal;
            color: var(--color-text-primary);

            border-radius: $border-radius-primary;
            border: solid 1px var(--color-input-border);
            background: transparent;

            &::placeholder {
                color: transparent;
            }

            &:hover {
                border-color: var(--color-input-border-hover);
            }

            &.display-time.invalid:not(.skip-error-style) {
                border-color: var(--color-input-border);
                outline-color: transparent;
            }
        }

        .clear-btn {
            display: none;
        }

        .time-picker-overlay {
            outline: none;
        }

        .dropdown {
            top: 115%;
            height: $timepicker-height;
            border: none;
            font-size: 16px;
            line-height: $timepicker-time-height;
            background: var(--color-component-bg-primary);
            border-radius: $border-radius-secondary;
            box-shadow: var(--box-shadow-primary);
            z-index: $z-index-dropdown;

            .select-list {
                height: $timepicker-height;

                ul {
                    width: 50px;
                    @include webkit-scrollbar-vertical($timepicker-height, var(--color-component-bg-primary), var(--color-scrollbar-thumb-bg));

                    li {
                        color: inherit;
                        padding: 0;

                        &:not(.hint):not([disabled]) {
                            &:hover {
                                color: inherit;
                                box-shadow: inset 0 0 0 1px var(--color-btn-primary-bg);
                                background: initial;
                            }

                            &.active {
                                color: var(--color-btn-primary);
                                background-color: var(--color-btn-primary-bg);
                            }
                        }
                    }

                    .hint {
                        font-size: 14px;
                        line-height: $timepicker-hint-height;
                        border-bottom: 1px solid var(--color-input-border);
                    }
                }
            }
        }
    }

    &.error {
        .vue__time-picker {
            input {
                color: var(--color-error);
                border: 1px solid var(--color-input-border-error);
            }
        }
    }
}
</style>